// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Components
import Icon from 'src/components/HTKit/Icon';
// Constants
import { ICON_ORIENTATION } from './constants';
// Styles
import styles from './styles.scss';

const ItemWithCaret = ({
  containerStyles,
  iconName,
  iconStyles,
  iconOpenStyles,
  iconOrientation,
  isOpen,
  onClick,
  text,
  textStyles,
  textOpenStyles,
}) => {
  const appliedTextStyles = cn(textStyles, {
    [textOpenStyles]: isOpen,
  });
  const appliedIconStyles = cn(iconStyles, {
    [iconOpenStyles]: isOpen,
  });
  const iconIsLeft = iconOrientation === ICON_ORIENTATION.left;
  const iconIsRight = iconOrientation === ICON_ORIENTATION.right;
  return (
    <div onClick={onClick} className={containerStyles}>
      {iconIsLeft && <Icon name={iconName} className={appliedIconStyles} />}
      <span className={appliedTextStyles}>{text}</span>
      {iconIsRight && <Icon name={iconName} className={appliedIconStyles} />}
    </div>
  );
};

ItemWithCaret.propTypes = {
  containerStyles: PropTypes.string,
  iconName: PropTypes.string,
  iconStyles: PropTypes.string,
  iconOrientation: PropTypes.string,
  iconOpenStyles: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string,
  textStyles: PropTypes.string,
  textOpenStyles: PropTypes.string,
};

ItemWithCaret.defaultProps = {
  iconName: 'chevron',
  iconOrientation: ICON_ORIENTATION.right,
  iconStyles: styles.chevron,
  iconOpenStyles: styles.chevronUp,
  isOpen: false,
  onClick: () => {},
};

export default React.memo(ItemWithCaret);

/*
  ItemWithCaret: A menu item that renders text and an icon

  Usage:
  import { ItemWithCaret, useItemCaretToggle, ICON_ORIENTATION } from 'src/components/HTKit/Elements/Menu';
  ...
  ...
  const { isOpen, toggle } = useItemCaretToggle();
  ...
  ...
  <ItemWithCaret
    containerStyles={styles.servicesContainer}
    iconOrientation={ICON_ORIENTATION.right}
    iconName="chevron"
    iconStyles={styles.chevron}
    iconOpenStyles={styles.chevronUp}
    isOpen={isOpen}
    onClick={toggle}
    text="Services"
    textStyles={servicesTextStyles}
    textOpenStyles={styles.servicesActive}
  />

  PropTypes:
    - containerStyles: Styles for the container
    - iconOrientation: Icon will render left or right of text
    - iconName: Name of the icon that will be passed to the <Icon /> component
    - iconStyles: Default styles applied when isOpen is false
    - iconOpenStyles: Styles applied when isOpen is true
    - isOpen: Boolean that triggers style changes for text and icon
    - onClick: optional callback function
    - text: Text to render next to the icon
    - textStyles: Default styles applied when isOpen is false
    - textOpenStyles: Styles applied when isOpen is true
*/
