import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import get from 'lodash/get';
import { htToast } from 'HTKit/Toast';
// Api Routes
import { selectRoutes } from 'src/apiRoutes';
// Utils
import { requestStarted, requestFinished, displayErrorsWithSnack } from 'src/utils/request';
import { logger } from 'src/utils/logger';
import { splitioSelectors, splitioConstants } from 'src/components/SplitIO';

// External Actions && Constants
import { pageLoadingError, pageLoaded } from 'src/actions/common';
import { addSuccessAppSnackNotice, addErrorAppSnackNotice } from 'src/actions/snackNotice';
import { LOAD_PAGE } from 'src/constants/common';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import {
  getSubscriptionPlansInfo,
  formatPlansForReducer,
  getUpsellPlanIds,
} from 'src/components/Plans/Pricing/utils';
import { bookNow } from 'src/components/BookNowButton/actions';
// Selectors
import { userPlanHolderSelector } from 'src/selectors/user';
import { paramsSelector } from 'src/selectors/router';
import { orderIsFromApiSelector, cartItemsJSSelector } from 'src/selectors/cart';
// Local Actions and constants
import { updateTrackingByUpsell, deriveBEUpsellChannel } from 'src/actions/tracking';
import { UPSELL_TRACKING } from 'src/constants/tracking';
import { updateCart } from '../AddSkuPage/actions';
import { showUpsellPlan, setHideUpsellCookie, getApiOrderAddMessage } from './utils';
import {
  REMOVE_ITEM,
  LOAD_RELATED_SKUS,
  PAGE_NAME,
  REMOVE_PLAN,
  ADD_PLAN_AND_START_BOOKING,
  UPDATE_QTY,
  ADD_COUPON_TO_CART,
  ADD_CART_BY_TOKEN_TO_CART,
} from './constants';
import {
  startBooking,
  setRelatedSkus,
  toggleAnotherService,
  loadPlanDetails,
  loadEstimatedCart,
  showUpsellCartVersion,
  toggleRemovePlanModal,
  togglePlanHolderModal,
  itemEdited,
  viewCartPage,
  fireIdentifyFromDeepLinkToken,
} from './actions';
import { fieldSaleAgentVerifyLayoutSelector } from '../FieldSales/selectors';
import HT_INFO from '../../constants/hellotech';

function* getEstimatedCart({ planId, cart }) {
  const routes = yield call(selectRoutes);
  const response = yield call(routes.cart.estimatePlan, { plan_id: planId });
  const {
    data: { cart: estimatedCart },
    err,
  } = response;
  if (!err) {
    const breakdownItems = get(estimatedCart, 'breakdown.items', []);
    if (breakdownItems.length) {
      const estimatedCartLength = breakdownItems.length;
      const currentCartLength = get(cart, 'breakdown.items', []).length;

      if (estimatedCartLength !== currentCartLength) {
        logger('CartPage')(new Error('Breakdown items counts do not match'));
        return false;
      }

      yield put(loadEstimatedCart({ cart: estimatedCart }));
      return true;
    }
    logger('CartPage')(new Error('Estimated cart does not have breakdown items'));
    return false;
  }
  logger('CartPage')(new Error(`Fetching estimated cart failed: ${JSON.stringify(response)}`));
  return false;
}

function* getPlanDetails({ planId }) {
  const routes = yield call(selectRoutes);
  const response = yield call(routes.plans.subscriptions.show, { id: planId });
  const {
    data: { plan },
    err,
  } = response;
  if (!err) {
    yield put(loadPlanDetails({ plan }));
  } else {
    yield put(displayErrorsWithSnack(response));
  }
}

function* loadRelatedSkus() {
  const routes = yield call(selectRoutes);
  const requestResult = yield call(routes.cart.getRelatedSkus);
  let skus = [];

  if (!requestResult.err) {
    skus = requestResult.data.skus;
  }

  yield put(setRelatedSkus(skus));
}

function* checkIfCanAddMoreServices() {
  const routes = yield call(selectRoutes);
  const requestResult = yield call(routes.cart.canAddMoreServices);

  if (!requestResult.err) {
    const {
      data: { result },
    } = requestResult;
    yield put(toggleAnotherService(result));
  }
}

/**
 * addCouponToCart():
 * Currently the BE requires a logged in user to add a coupon to the cart. We are using The {force: true} parameter
 * to bypass this restriction. Use successMsg and errorMsg to pass in a custom message
 */

function* addCouponToCart({ code = '', successMsg = '', errorMsg = '', withToast = false } = {}) {
  if (!code) return null;
  const routes = yield call(selectRoutes);
  const response = yield call(routes.cart.addCoupon, { code, force: true });
  if (response.err) {
    return yield put(
      addErrorAppSnackNotice({ content: errorMsg || response.data.errors.join('. ') }),
    );
  }
  if (successMsg) {
    if (withToast) {
      htToast(successMsg);
    } else {
      yield put(
        addSuccessAppSnackNotice({
          content: successMsg,
        }),
      );
    }
  }

  return yield put(updateCart({ cart: response.data }));
}

function* addAlfredBundle() {
  const params = yield select(paramsSelector);
  const { sku, plan, sku_redirect: skuRedirect, type } = params;
  if (!([sku, plan, skuRedirect].some(Boolean) && type === 'bundle')) return;

  // Get current users items and plan from their cart
  const cart = yield select((state) => state.getIn(['entities', 'cart'], null));
  const { items = [], plan: cartPlan } = cart ? cart.toJS() : {};
  // Has previously carted items determinant (items/plan)
  const hasCartedItems = items.length || cartPlan;
  const routes = yield call(selectRoutes);
  // array'atize our skus
  const skuIds = sku && sku.split(',');
  // error array. push in errors.
  const errors = [];

  // method util: do we have errors;
  const hasErrors = () => errors.length > 0;
  // method util: determine if we should proceed;
  const proceedWithOperation = (key) => key && !hasErrors();

  // User has carted items. We'll empty cart before adding bundle
  if (hasCartedItems) {
    const response = yield call(routes.cart.emptyCart);

    if (response.err) {
      errors.push(`emptyCart: ${response.err}`);
    }
  }

  // Iterate thru all passed skus. If any errors, push to error.
  if (proceedWithOperation(skuIds)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const id of skuIds) {
      const { data, err } = yield call(routes.cart.addItem, {
        item: { skuId: +id, quantity: 1, questions: {} },
      });

      if (err) {
        errors.push(err);
      } else {
        yield put(updateCart({ cart: data.cart }));
      }
    }
  }

  // Add plan. If any errors, push to error.
  if (proceedWithOperation(plan)) {
    const { data, err } = yield call(routes.cart.addPlan, { plan_id: plan });

    if (err) {
      errors.push(`AddPlan: ${err}`);
    } else {
      yield put(updateCart({ cart: data.cart }));
    }
  }

  // If there are any errors, lets display snack and give user a chance to try adding bundle again.
  if (hasErrors()) {
    const defaultAlfredPhone = HT_INFO.phone.customers.alfred;

    yield put(
      addErrorAppSnackNotice({
        content: `<span>There was an error adding your bundle to the cart. Please call HelloTech at <a href='${defaultAlfredPhone.link}'> ${defaultAlfredPhone.title}</a></span>`,
      }),
    );
    yield call(routes.cart.emptyCart);

    logger('CartPage: addAlfredBundle')(new Error(errors));
  }

  // If we have a skuRedirect and no errors, redirect to add-sku page.
  // Since this happens after the error check, we can circumvent the operation check.
  // But, keep it for uniformity sake.
  if (proceedWithOperation(skuRedirect)) {
    yield put(bookNow(skuRedirect));
  }
}

/**
 * checkForDeepLinks() :
 * Add SHP plan: http://${HOST}/cart?add_plan_id=1
 * Add SHP plan with coupon: http://${HOST}/cart?add_plan_id=1&add_coupon_id=${COUPON_CODE}
 */
function* checkForDeepLinks() {
  const params = yield select(paramsSelector);
  const planId = Number(params.add_plan_id);

  // add plan deep link
  if (planId) {
    const routes = yield call(selectRoutes);
    const { data, err } = yield call(routes.cart.addPlan, { plan_id: planId });

    if (err) {
      yield put(addErrorAppSnackNotice({ content: data.errors.join('. ') }));
    } else {
      htToast('Added to cart!');
      const couponId = params.add_coupon_id;
      if (couponId) {
        // Add coupon for plan - deep link
        yield call(addCouponToCart, { code: couponId });
      }
    }
  }
}

function* pageSaga({ isPlanPricingTest = false, token = '' }) {
  /* Runs in response to LOAD_PAGE action */
  yield call(checkForDeepLinks);

  // If we have bundle params
  // We need the cart/geekatwo cookie at this point.
  yield call(addAlfredBundle);

  const isFieldSaleFlow = yield select(fieldSaleAgentVerifyLayoutSelector);
  const isApiOrder = yield select(orderIsFromApiSelector);
  const routes = yield call(selectRoutes);
  const forceRefresh = true; // TODO: fix me later on (just not to query for taxes)

  let cart = yield select((state) => state.getIn(['entities', 'cart']));
  /* If we have a token, we'll need to fetch that cart by token */
  if (!cart || !cart.get('breakdown') || forceRefresh || token) {
    cart = null;
  }
  if (cart) {
    cart = cart.toJS();
  } else {
    let requestResult;
    let requestTokenResult;

    /* We have a token, use it. */
    if (token) {
      requestTokenResult = yield call(
        routes.cart.getCartByToken,
        { token },
        { breakdown: true, no_taxes: true },
      );
      if (!requestTokenResult.err) {
        cart = requestTokenResult?.data?.cart;
      } else {
        logger('CartPage')(new Error(`Invalid token: ${token}`));
      }
    }

    /* If token was not feasible, fetch session cart */
    if (!cart) {
      requestResult = yield call(routes.cart.find, { breakdown: true, no_taxes: true });
      if (!requestResult.err) {
        cart = requestResult.data.cart;
      }
    }

    if (!cart) {
      yield put(pageLoadingError(PAGE_NAME, requestResult));
      return;
    }
  }

  if (cart.skipCart && !isFieldSaleFlow) {
    /* cart.skipCart is a property provided by the BE based on sku properties */
    yield put(updateCart({ cart }));
    yield put(startBooking(cart, { stage: 'verification' }));
    return;
  }
  let meta;
  if (!cart.partner) {
    meta = {
      rsq: [
        {
          eventType: 'track',
          eventPayload: {
            actionName: 'shopping_cart',
            items: cart.items,
            idModifier: cart.remote ? 'R' : 'O',
            item_type: 'individual',
          },
        },
      ],
    };
  }

  /* Carts gotten with a token need an identify call */
  if (token) {
    yield put(fireIdentifyFromDeepLinkToken(cart));
  }

  yield call(loadRelatedSkus);
  yield call(checkIfCanAddMoreServices);

  yield call(loadUserProfileSaga, PAGE_NAME);
  const user = yield select((state) => state.get('user'));

  // Get estimated cart for plan upsell if applicable
  // We're ok with using yearlyID since the monthly plans are clones of their yearly counterpart
  // UPDATE^ Changing to monthlyID since the test is going to be turned off and we are going to be using monthly upsell.
  yield put(showUpsellCartVersion(false));
  if (showUpsellPlan({ cart: fromJS(cart), user }) && !isFieldSaleFlow) {
    const { monthlyID } = yield call(getUpsellPlanIds, { isPlanPricingTest });
    const getEstimatedCartSuccess = yield call(getEstimatedCart, { planId: monthlyID, cart });
    if (getEstimatedCartSuccess) {
      yield call(getPlanDetails, { planId: monthlyID });
      yield put(showUpsellCartVersion(true));
    }
  } else if (cart.plan) {
    // For RegularCart. Details are needed for <RemoveSubscriptionFromCartModal />
    const planId = cart.plan.id;
    yield call(getPlanDetails, { planId });
  }

  // fetch upsell subscription plans info - for monthly/yearly subscriptions
  let smartHomePlans;
  if (showUpsellPlan({ cart: fromJS(cart), user }) && !isFieldSaleFlow) {
    const plansResult = yield call(getSubscriptionPlansInfo, { api: routes });
    if (!plansResult.err) {
      const {
        data: { plans: planProducts },
      } = plansResult;
      smartHomePlans = formatPlansForReducer({ planProducts, featured: false, isPlanPricingTest });
    }
  }

  yield put(pageLoaded(PAGE_NAME, { cart, meta, plansInfo: smartHomePlans }));
  yield put(viewCartPage(cart));

  const page = yield select((state) => state.getIn(['pages', PAGE_NAME]));

  /* SNACKBAR/TOAST NOTIFICATION ITEM ADDED */
  if (page.get('showItemWasAdded')) {
    if (isApiOrder) {
      yield put(addSuccessAppSnackNotice({ content: getApiOrderAddMessage(cart) }));
    } else {
      htToast('Added to cart!');
    }
  }

  /* TOAST NOTIFICATION ITEM EDITED */
  if (page.get('showItemWasEdited')) {
    htToast('Edits saved.');
  }
}

function* removeItemSaga({ itemIndex }) {
  const isFieldSaleFlow = yield select(fieldSaleAgentVerifyLayoutSelector);
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const response = yield call(routes.cart.removeItem, { itemIndex, no_taxes: true });
  if (response.err) {
    yield put(
      displayErrorsWithSnack(response, {
        customContent: 'Failed to remove this service from your cart!',
      }),
    );
  } else {
    const { cart } = response.data;
    yield put(updateCart({ cart }));
    htToast('Removed from cart.');
    yield call(loadRelatedSkus);
    yield call(checkIfCanAddMoreServices);
    const user = yield select((state) => state.get('user'));

    const isPlanPricingTest = yield select(
      splitioSelectors.treatmentSelector(splitioConstants.SPLITIONAME_PLAN_PRICING),
    );
    const { monthlyID } = getUpsellPlanIds({ isPlanPricingTest });

    if (showUpsellPlan({ cart: fromJS(cart), user }) && !isFieldSaleFlow) {
      const getEstimatedCartSuccess = yield call(getEstimatedCart, {
        planId: monthlyID,
        cart,
      });
      if (!getEstimatedCartSuccess) {
        yield put(showUpsellCartVersion(false));
      }
    } else {
      yield put(showUpsellCartVersion(false));
    }
  }
  yield put(requestFinished());
}

function* removePlan() {
  const routes = yield call(selectRoutes);
  const prevCart = yield select((state) => state.getIn(['entities', 'cart']));

  yield put(requestStarted());
  const response = yield call(routes.cart.removePlan, { no_taxes: true });
  const {
    data: { cart },
    err,
  } = response;

  if (!err) {
    yield put(updateCart({ cart }));
    htToast('Removed from cart.');
    setHideUpsellCookie();

    if (prevCart) {
      const plan = prevCart.get('plan').toJS();
      yield put(
        updateTrackingByUpsell({ plan, partner: cart.partner, event: UPSELL_TRACKING.removed }),
      );
    }
  } else {
    yield put(
      displayErrorsWithSnack(response, { customContent: 'Failed to remove from your cart!' }),
    );
  }
  const page = yield select((state) => state.getIn(['pages', PAGE_NAME]));
  if (page.get('showRemovePlanModal')) {
    yield put(toggleRemovePlanModal());
  }
  yield put(requestFinished());
}

function* addPlanAndStartBooking({ planId, isPasswordless = false }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());

  const isPlanHolder = yield select((state) => userPlanHolderSelector(state));
  if (isPlanHolder) {
    yield put(togglePlanHolderModal(true));
    yield put(requestFinished());
    return;
  }

  const pathname = yield select((state) => state.getIn(['router', 'location', 'pathname']));
  /**
   * For Data team we are sending the same value as the Segment's events's `upsell_name` property
   * with plan creation request, but named as `plan_upsell_channel`
   */
  const planUpsellChannel = deriveBEUpsellChannel(pathname) || null;

  const response = yield call(routes.cart.selectPlan, {
    plan_id: planId,
    ...(planUpsellChannel ? { plan_upsell_channel: planUpsellChannel } : {}),
  });
  const { err } = response;

  if (!err) {
    const plan = get(response, 'data.cart.plan', {});
    const partner = get(response, 'data.cart.partner', {});

    yield put(startBooking(null, { stage: 'verification', isPasswordless }));
    yield put(requestFinished());
    yield put(updateTrackingByUpsell({ plan, partner, event: UPSELL_TRACKING.added }));
  } else {
    yield put(displayErrorsWithSnack(response, { customContent: 'Failed to add to your cart!' }));
    yield put(requestFinished());
  }
}

function* updateQuantity({ quantity, index }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());
  const items = yield select(cartItemsJSSelector);
  const matchedItem = items.find((item, itemIndex) => itemIndex === index);
  if (matchedItem) {
    const item = { item: { ...matchedItem, quantity }, index };
    const response = yield call(routes.cart.updateQuantity, item);
    if (response.err) {
      yield put(
        displayErrorsWithSnack(response, {
          customContent: 'Failed to update quantity!',
        }),
      );
    } else {
      const { cart } = response.data;

      const isPlanPricingTest = yield select(
        splitioSelectors.treatmentSelector(splitioConstants.SPLITIONAME_PLAN_PRICING),
      );
      const isPricingTest = isPlanPricingTest === 'ON';
      const { monthlyID } = getUpsellPlanIds({ isPricingTest });
      const getEstimatedCartSuccess = yield call(getEstimatedCart, {
        planId: monthlyID,
        cart,
      });
      if (getEstimatedCartSuccess) {
        yield call(getPlanDetails, { planId: monthlyID });
      }
      yield put(itemEdited());
      yield put(updateCart({ cart }));
    }
  }
  yield put(requestFinished());
}

/**
 * We get a token in the url /cart/XyWn87r and get the saved cart, and saturate redux.
 *
 * @param token
 * @returns {Generator<*, void, *>}
 */
function* addCartByTokenToCart({ token }) {
  const routes = yield call(selectRoutes);
  yield put(requestStarted());

  const response = yield call(routes.cart.getCartByToken, { token });
  const { err } = response;

  if (!err) {
    const { cart } = response.data;
    yield put(updateCart({ cart }));
    yield put(requestFinished());
    yield put(fireIdentifyFromDeepLinkToken(cart));
  } else {
    logger('CartPage')(new Error(`Invalid token: ${token}`));
    yield put(requestFinished());
  }
}

function* pageFlow() {
  yield takeLatest((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeEvery(REMOVE_ITEM, removeItemSaga);
  yield takeEvery(LOAD_RELATED_SKUS, loadRelatedSkus);
  yield takeLatest(REMOVE_PLAN, removePlan);
  yield takeLatest(ADD_PLAN_AND_START_BOOKING, addPlanAndStartBooking);
  yield takeLatest(UPDATE_QTY, updateQuantity);
  yield takeLatest(ADD_COUPON_TO_CART, addCouponToCart);
  yield takeLatest(ADD_CART_BY_TOKEN_TO_CART, addCartByTokenToCart);
}

export default [pageFlow];
