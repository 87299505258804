import { call, put, takeLatest, select, race, delay, take } from 'redux-saga/effects';
import _ from 'lodash';
import { getTreatments } from '@splitsoftware/splitio-redux';
import { push, cartPath, skuRecommendPath } from 'src/utils/paths';
import {
  SPLITIONAME_ADD_SKU_CONFIRM_AND_RECOMMENDATIONS,
  ON,
} from 'src/components/SplitIO/constants';
import {
  displayErrors,
  requestStarted,
  requestFinished,
  displayErrorsWithSnack,
} from 'src/utils/request';
import { LOAD_PAGE } from 'src/constants/common';
import {
  layoutUpdate,
  pageLoadingError,
  pageLoaded,
  invalidForm,
  clearFormErrors,
} from 'src/actions/common';
import { loadUserProfileSaga } from 'src/sagas/common/user';
import { selectRoutes } from 'src/apiRoutes';
import {
  ANSWER_CHANGE,
  ANSWER_QUANTITY_CHANGE,
  ANSWER_CHANGE_DEVICE_INSTANTIATE,
} from 'src/components/PreQuestion/constants';
import {
  itemAdded,
  itemEdited,
  showModalMultipleAutoApplyCoupons,
  apiItemUpdated,
} from 'src/containers/CartPage/actions';
import { isQuestionAnswerFlowSelector } from 'src/containers/AddSkuPage/QuestionsFlowPage/selectors';
import { orderIsFromApiSelector } from 'src/selectors/cart';
import { validatedPinSelector } from 'src/containers/FinishOrder/PINRedemption/selectors';
import {
  isPinRequiredToAddSkuId,
  isPinRequiredForPartnerSku,
  getCookiesPinData,
} from 'src/containers/FinishOrder/PINRedemption/utils';
import { skuRecommendations } from 'src/containers/Recommendations/data/skuRecommendations';
import { cartHasItemsAndWorkflowSelector } from 'src/containers/EV/ev.selectors';
import {
  cartWorkflowStatusIsSiteVisit,
  cartWorkflowTypeIsEVInstallOnly,
  buildEVPaths,
} from 'src/containers/EV/utils';
import {
  PAGE_NAME,
  UPDATE_ITEM_PRICE,
  ADD_TO_CART,
  REMOTE_QUESTION,
  CONFIRM_ITEM,
  ADD_TO_CART_Q_FLOW,
  ADD_PRODUCT_TO_CART,
  QUESTION_TYPES,
  POPULATE_NEW_ITEM_WITH_CART_ANSWERS,
} from './constants';
import {
  updateItemPrice,
  itemPriceUpdated,
  updateCart,
  updateSkuQuestions,
  populateCartAnswers,
  pinRequiredErrorPageSnackNotice,
} from './actions';
import { inEditSkuModeSelector, skuItemIndexSelector } from './selectors';
import { resolveAddSkuPageLayout, formatItemPropsForSaga } from './utils';
import { USER_TYPES } from 'src/constants/user';

function* populateNewItemWithCart({ cart, sku }) {
  // if in edit mode, prepopulate `newItem` reducer state with answers
  const inEditMode = yield select(inEditSkuModeSelector);

  const cartHasItemsAndWorkflow = yield select(cartHasItemsAndWorkflowSelector);
  if (inEditMode || cartHasItemsAndWorkflow) {
    let itemIndex = yield select(skuItemIndexSelector);
    itemIndex = cartHasItemsAndWorkflow ? 0 : itemIndex;
    const cartItem = cart.items[itemIndex];

    if (!cartItem) return;
    const cartItemQandA = cartItem.questions;
    const cartItemQandAFiltered = _.pickBy(cartItemQandA, (qa) => {
      return qa && !_.isEmpty(qa);
    });
    const { quantity } = cartItem;
    const inHome = !cart.remote && !!cart.items.length && sku.remoteSavings;
    if (inHome) {
      cartItemQandAFiltered.remote = { id: 'remote-false' };
    }

    yield put(populateCartAnswers({ cartQandA: cartItemQandAFiltered, quantity }));
  }
}

function* populateNewItemWithCartAnswersSaga({ payload }) {
  const { cart, sku } = payload;
  yield call(populateNewItemWithCart, { cart, sku });
}

/* Runs in response to LOAD_PAGE action */
function* pageSaga({ id, email, isConfirmation, updateLayout = true, manualCartToken }) {
  const routes = yield call(selectRoutes);

  // get cart data
  let cart = yield select((state) => state.getIn(['entities', 'cart']));
  if (!cart || !cart.get('breakdown')) {
    cart = null;
  }

  const cartTokenDoesNotMatch = () =>
    cart && manualCartToken && cart.get('token') !== manualCartToken;
  if (cartTokenDoesNotMatch()) {
    cart = null;
  }

  if (cart) {
    cart = cart.toJS();
  } else {
    const requestResult = yield call(routes.cart.find, {
      breakdown: true,
      ...(manualCartToken && { token: manualCartToken }),
    });
    if (!requestResult.err) {
      cart = requestResult.data.cart;
    } else {
      yield put(pageLoadingError('cart', requestResult));
      return;
    }
  }

  yield put(updateCart({ cart }));

  // get sku data
  let sku = yield select((state) => state.getIn(['entities', 'skus', String(id)], null));
  if (sku) {
    sku = sku.toJS();
  } else {
    const requestResult = yield call(routes.skus.find, { id });
    if (!requestResult.err) {
      sku = requestResult.data.sku;
    } else {
      yield put(pageLoadingError(PAGE_NAME, requestResult));
      return;
    }
  }

  // get questions data
  let questions = yield select((state) =>
    state.getIn(['entities', 'questions', id.toString()], null),
  );
  if (questions) {
    questions = questions.toJS();
  } else {
    const requestResult = yield call(routes.skus.questions, { id });
    if (!requestResult.err) {
      questions = requestResult.data.questions;
    } else {
      yield put(pageLoadingError(PAGE_NAME, requestResult));
      return;
    }
  }

  // modify questions if sku can be remote
  if (sku.remote && !sku.remoteOnly) {
    if (!_.find(questions, { id: REMOTE_QUESTION.id })) {
      questions.push(_.cloneDeep(REMOTE_QUESTION));
    }
  }

  // don't load if sku can only be viewed via email link
  if (sku.restrictedByEmail) {
    const response = yield call(routes.skus.validatePermission, { id }, { email });
    if (response.err) {
      yield put(displayErrors(response));
      yield put(pageLoadingError(PAGE_NAME, response));
      return;
    }
  }

  // get user data (only used for new qFlow + EV)
  yield call(loadUserProfileSaga, PAGE_NAME);
  /* at this stage state does not have newItem, lets get at 'sku' and pass it thru */
  const newSkuQAFlow = yield select((state) => {
    const newState = state;
    return isQuestionAnswerFlowSelector(newState.mergeDeep({ sku }));
  });

  if (updateLayout) {
    // For EV we wanted to be able to call the PAGE_LOAD action without updating the layout -GH Aug 23, 2023
    const layout = resolveAddSkuPageLayout({
      sku,
      isConfirmation,
      newSkuQAFlow,
      cart,
    });
    yield put(layoutUpdate(layout));
  }

  yield put(pageLoaded(PAGE_NAME, { sku, questions, email }));
  yield call(populateNewItemWithCart, { cart, sku });
  yield put(updateItemPrice());
}

function* itemSaga() {
  const routes = yield call(selectRoutes);

  const item = yield select((state) => state.get('newItem'));
  // TODO: Make this work with new question.inputType === 'media'.
  // When the input type is media the app breaks on the next line because item.get('questions') is undefined.
  // This saga runs in response to `yield put(updateItemPrice())` in the `pageSaga` function above.
  // Unclear as to the reason why it breaks. Also see the getErrors() function below that performs explicit checks
  // for accepted inputTypes.
  const sku = yield select((state) =>
    state.getIn(['entities', 'skus', item.get('skuId').toString()]),
  );
  const cart = yield select((state) => state.getIn(['entities', 'cart']));
  const inHome = !cart.get('remote') && !!cart.get('items').size && sku.get('remoteSavings');

  /**
   * In order to get the item_price.breakdown for items already added to the cart the BE needs the index of the item in the cart
   * @type {import('src/containers/AddSkuPage/selectors.js').SkuItemIndex}
   */
  const itemIndexFromSelector = yield select(skuItemIndexSelector);
  const requestObj = { item: item.toJS(), inHome };
  if (typeof itemIndexFromSelector === 'number') requestObj.index = itemIndexFromSelector;

  const requestResult = yield call(routes.cart.itemPrice, requestObj);
  if (!requestResult.err) {
    const { price, questions, breakdown } = requestResult.data;
    yield put(itemPriceUpdated({ price, breakdown }));
    if (questions) {
      if (sku.get('remote') && !sku.get('remoteOnly')) {
        if (!_.find(questions, { id: REMOTE_QUESTION.id })) {
          questions.push(_.cloneDeep(REMOTE_QUESTION));
        }
      }
      yield put(updateSkuQuestions({ skuId: sku.get('id'), questions }));
    }
  } else {
    yield put(displayErrors(requestResult));
  }
}

export function getErrors({ item, questions }) {
  const requiredQuestions = questions.filter((q) => q.get('required'));
  const errors = {};
  requiredQuestions.forEach((q) => {
    let answer = item.getIn(['questions', q.get('id').toString()], null);
    answer = answer && answer.toJS();
    let error = null;
    switch (q.get('inputType')) {
      case QUESTION_TYPES.INPUT:
        if (_.trim(answer.text) === '') {
          error = 'This field is required';
        }
        break;
      case QUESTION_TYPES.TEXT_AREA:
        if (_.trim(answer.text) === '') {
          error = 'This field is required';
        }
        break;
      case QUESTION_TYPES.DROPDOWN:
        if (answer === null) {
          error = 'This field is required';
        }
        break;
      case QUESTION_TYPES.CHECKBOX:
        if (_.isEmpty(answer)) {
          error = 'Please select at least one option';
        }
        break;
      case QUESTION_TYPES.DEVICE: {
        const required = ['make', 'model'];
        const getDeviceErrors = required.reduce((seed, key) => {
          const keyRef = _.trim(_.get(answer, key) || '');
          // eslint-disable-next-line no-param-reassign
          if (!keyRef) seed[key] = 'This field is required';
          return seed;
        }, {});

        error = Object.values(getDeviceErrors).length ? getDeviceErrors : null;
        break;
      }
      case QUESTION_TYPES.MEDIA:
        if (!answer.media || answer.media.length === 0) {
          error = 'This field is required';
        }
        break;
      default:
        throw new Error(`Unknown input type: ${q.get('inputType')}`);
    }
    if (error) {
      errors[q.get('id').toString()] = error;
    }
  });
  return errors;
}

function* getPinData({ skuId, partnerName }) {
  const pinDataRedux = yield select(validatedPinSelector);
  const pinDataCookie = getCookiesPinData(skuId);
  const pinData = pinDataRedux || pinDataCookie;
  const isMissingPin =
    (isPinRequiredToAddSkuId(skuId) || isPinRequiredForPartnerSku(partnerName)) && !pinData;
  return { pinData, isMissingPin };
}

function* addToCartSaga({ payload }) {
  const { manualCartToken, onSuccess } = payload;
  const routes = yield call(selectRoutes);
  const email = yield select((state) => state.getIn(['pages', PAGE_NAME, 'email']));
  const item = yield select((state) => state.get('newItem'));
  const skuId = item && item.get('skuId');
  const partner = item && item.getIn(['partner', 'layoutName'], null);
  const questions = yield select((state) =>
    state.getIn(['entities', 'questions', item.get('skuId').toString()]),
  );

  const currentCouponText = yield select((state) =>
    state.getIn(['entities', 'cart', 'coupon', 'text']),
  );
  const autoApplyCouponAlreadyApplied = yield select((state) =>
    state.getIn(['entities', 'cart', 'coupon', 'autoApply']),
  );
  const newItemAutoApplyCoupon = yield select((state) =>
    state.getIn(['newItem', 'autoApplyCoupon']),
  );
  const renderMultipleAutoApplyCouponsModal =
    autoApplyCouponAlreadyApplied &&
    !!newItemAutoApplyCoupon &&
    currentCouponText !== newItemAutoApplyCoupon.get('text');

  const { pinData, isMissingPin } = yield call(getPinData, { skuId, partnerName: partner });

  if (isMissingPin) {
    yield put(pinRequiredErrorPageSnackNotice({ partner }));
    return;
  }

  yield put(clearFormErrors(PAGE_NAME));
  const errors = getErrors({ item, questions });
  if (Object.keys(errors).length > 0) {
    yield put(invalidForm(PAGE_NAME, errors));
    return;
  }
  yield put(requestStarted());

  // Prepare any additional data that needs to be sent with item
  const itemDetails = item.merge(pinData);
  const requestData = {
    item: itemDetails,
    email,
    ...(manualCartToken && { token: manualCartToken }),
  };
  const response = yield call(routes.cart.addItem, requestData);

  yield put(requestFinished());
  if (response.err) {
    yield put(displayErrors(response));
    return;
  }
  const { cart } = response.data;
  const addedItem = cart.breakdown.items[0];
  addedItem.partner = cart.partner;

  if (renderMultipleAutoApplyCouponsModal) {
    yield put(showModalMultipleAutoApplyCoupons());
  }
  yield put(updateCart({ cart }));
  yield put(itemAdded(addedItem, cart));

  if (onSuccess) {
    onSuccess(cart);
  }

  const redirectToRecommendationsConfirm = yield call(determineToRedirectToRecommendConfirm, {
    split: SPLITIONAME_ADD_SKU_CONFIRM_AND_RECOMMENDATIONS,
    id: skuId,
  });

  if (cartWorkflowTypeIsEVInstallOnly(cart)) {
    // Don't do anything here. The SkuQuestions page will handle the redirect.
  } else if (cartWorkflowStatusIsSiteVisit(cart)) {
    yield put(push(buildEVPaths({ pathType: 'scheduling', token: cart.token })));
  } else if (redirectToRecommendationsConfirm) {
    yield put(push(skuRecommendPath(skuId)));
  } else {
    yield put(push(cartPath));
  }
}

// Update item in cart
function* confirmCartItemSaga({ payload }) {
  /*
    Api orders already have an order record created, and clients are sent to our site to "redeem"
    and confirm the details of their order.

    This function also handles non-API carts that have items added to the cart that the customer edits/updates.
  */
  const { manualCartToken, onSuccess } = payload;
  const routes = yield call(selectRoutes);
  const email = yield select((state) => state.getIn(['pages', PAGE_NAME, 'email']));
  const item = yield select((state) => state.get('newItem'));
  const skuId = item.get('skuId').toString();
  const itemIndex = yield select(skuItemIndexSelector);
  const questions = yield select((state) => state.getIn(['entities', 'questions', skuId]));
  const isApiOrder = yield select(orderIsFromApiSelector);

  yield put(clearFormErrors(PAGE_NAME));
  const errors = getErrors({ item, questions });
  if (Object.keys(errors).length > 0) {
    yield put(invalidForm(PAGE_NAME, errors));
    return;
  }

  yield put(requestStarted());
  const inEditMode = yield select(inEditSkuModeSelector);
  const index = itemIndex || 0;
  const response = yield call(routes.cart.updateItem, {
    index,
    item,
    email,
    ...(manualCartToken && { token: manualCartToken }),
  });
  yield put(requestFinished());
  if (response.err) {
    yield put(displayErrors(response));
    return;
  }
  const { cart } = response.data;
  yield put(updateCart({ cart }));

  if (onSuccess) {
    onSuccess(cart);
  }

  /*
    Api orders that have questions that are being answered for the first
    time should show an "Item added!" message when returning to Cart Page
    -GH Aug 14, 2020
  */
  if (isApiOrder && !inEditMode) {
    yield put(apiItemUpdated());
  }
  if (inEditMode) {
    yield put(itemEdited());
  }

  if (cartWorkflowTypeIsEVInstallOnly(cart)) {
    // Early return here to allow the caller to handle any redirects.
    yield put(itemEdited());
    return;
  }

  if (cartWorkflowStatusIsSiteVisit(cart)) {
    // THIS IS FOR THE ORIGINAL EV FLOW WHICH HAS A QUOTE (SITE VISIT). `workflow.type === 'ev'`
    if (inEditMode) {
      yield put(push(buildEVPaths({ pathType: 'review', token: cart.token })));
      return;
    }
    yield put(push(buildEVPaths({ pathType: 'scheduling', token: cart.token })));
    return;
  }

  yield put(push(cartPath));
}

export const EV_DEBOUNCE_TIME = 2000;
/**
 * On the QuoteGenerationPage, we automatically update the cart to save the technician's progress as they answer questions.
 */
function* handleEVInstallationAnswerChange({ question = {}, token }) {
  const { inputType } = question;
  const isInputOrTextAreaOrDeviceType = [
    QUESTION_TYPES.INPUT,
    QUESTION_TYPES.TEXT_AREA,
    QUESTION_TYPES.DEVICE,
  ].includes(inputType);

  /*
    We should only updateItemPrice() if the question is not an input, textarea or device type because
    those types of questions don't affect the price of the item.
  */
  if (!isInputOrTextAreaOrDeviceType) {
    yield put(updateItemPrice());
  }

  const routes = yield call(selectRoutes);
  const email = yield select((state) => state.getIn(['pages', PAGE_NAME, 'email']));
  const evItemIndex = 0; // We are assuming there is only one item in the cart.

  const item = yield select((state) => state.get('newItem'));

  /** Custom adjustments will either be an array of objects or undefined */
  const customAdjustments = yield select((state) =>
    state.getIn(['entities', 'cart', 'items', '0', 'adjustments']),
  );
  let itemToSend = item;
  if (customAdjustments) {
    itemToSend = item.set('adjustments', customAdjustments);
  }

  /*
    Debounce logic using race and delay.
    We set up a race between a delay (our debounce time) and another occurrence of the triggering action.
    If no other action is dispatched before the delay finishes, we proceed with the API call.
  */
  const { timeout } = yield race({
    timeout: delay(EV_DEBOUNCE_TIME),
    newAction: take(ANSWER_CHANGE), // Listen for another ANSWER_CHANGE action.
    newAction2: take(ANSWER_CHANGE_DEVICE_INSTANTIATE), // Listen for another ANSWER_CHANGE_DEVICE_INSTANTIATE action.
  });

  if (!timeout) {
    /*
      If the delay didn't finish (i.e., another action was dispatched before the delay ended),
      we don't proceed with the API call. We'll wait for the next action to trigger this saga.
    */
    return;
  }

  const response = yield call(routes.cart.updateItem, {
    index: evItemIndex,
    item: itemToSend,
    email,
    token,
  });
  if (response.err) {
    yield put(displayErrors(response));
    return;
  }
  const { cart } = response.data;
  yield put(updateCart({ cart }));
}

function* answerChangeSaga({ question = {} }) {
  const skuId = yield select((state) => state.getIn(['newItem', 'skuId']));
  const evInstallationSkuIds = yield select((state) =>
    state.getIn(['entities', 'cart', 'workflow', 'steps', 'installation', 'skuIds'], []),
  );
  const userType = yield select((state) => state.getIn(['user', 'type']));
  const isDeprecatedEvInstallationFlow =
    userType === USER_TYPES.Tech && evInstallationSkuIds.includes(skuId);

  if (isDeprecatedEvInstallationFlow) {
    const token = yield select((state) => state.getIn(['entities', 'cart', 'token']));
    yield call(handleEVInstallationAnswerChange, { question, skuId, token });
    return;
  }
  const isInputType = question && question.inputType === QUESTION_TYPES.INPUT;
  const isDeviceType = question && question.inputType === QUESTION_TYPES.DEVICE;
  if (isInputType || isDeviceType) {
    return;
  }
  yield put(updateItemPrice());
}

function* answerDeviceChangeSaga({ question = {} }) {
  const skuId = yield select((state) => state.getIn(['newItem', 'skuId']));
  const token = yield select((state) => state.getIn(['entities', 'cart', 'token']));
  const userType = yield select((state) => state.getIn(['user', 'type']));
  const evInstallationSkuIds = yield select((state) =>
    state.getIn(['entities', 'cart', 'workflow', 'steps', 'installation', 'skuIds'], []),
  );
  const isDeprecatedEvInstallationFlow =
    userType === USER_TYPES.Tech && evInstallationSkuIds.includes(skuId);

  yield put(updateItemPrice());

  if (isDeprecatedEvInstallationFlow) {
    yield call(handleEVInstallationAnswerChange, { question, skuId, token });
  }
}

function* addToCartQFlowSaga({ completedAnswers }) {
  yield put(requestStarted());
  const routes = yield call(selectRoutes);

  // getting lazy here - JKo
  const newItem = yield select((state) => state.get('newItem'));
  const skuId = newItem.get('skuId');

  const itemInfo = formatItemPropsForSaga({ completedAnswers });
  const item = { skuId, ...itemInfo };

  const response = yield call(routes.cart.addItem, { item });
  const {
    data: { cart },
    err,
  } = response;

  yield put(requestFinished());
  if (err) {
    yield put(displayErrorsWithSnack(response));
    return;
  }
  const addedItem = cart.breakdown.items[0];
  addedItem.partner = cart.partner;

  yield put(updateCart({ cart }));
  yield put(itemAdded(addedItem, cart));

  const redirectToRecommendationsConfirm = yield call(determineToRedirectToRecommendConfirm, {
    split: SPLITIONAME_ADD_SKU_CONFIRM_AND_RECOMMENDATIONS,
    id: skuId,
  });

  if (redirectToRecommendationsConfirm) {
    yield put(push(skuRecommendPath(skuId)));
  } else {
    yield put(push(cartPath));
  }
}

function* addProductToCartSaga({ id }) {
  const routes = yield call(selectRoutes);
  let sku = yield select((state) => state.getIn(['entities', 'skus', String(id)], null));
  if (!sku) {
    const requestResult = yield call(routes.skus.find, { id });
    if (!requestResult.err) {
      sku = requestResult.data.sku;
    } else {
      yield put(pageLoadingError(PAGE_NAME, requestResult));
      return;
    }
  }
  const item = { item: { skuId: id, questions: {} } };
  yield put(requestStarted());
  const response = yield call(routes.cart.addItem, item);
  const {
    data: { cart },
    err,
  } = response;
  yield put(requestFinished());

  if (err) {
    yield put(displayErrorsWithSnack(response));
    return;
  }
  const addedItem = cart.breakdown.items[0];
  yield put(updateCart({ cart }));
  yield put(itemAdded(addedItem, cart));

  const redirectToRecommendationsConfirm = yield call(determineToRedirectToRecommendConfirm, {
    split: SPLITIONAME_ADD_SKU_CONFIRM_AND_RECOMMENDATIONS,
    id,
  });

  if (redirectToRecommendationsConfirm) {
    yield put(push(skuRecommendPath(id)));
  } else {
    yield put(push(cartPath));
  }
}

function* pageFlow() {
  yield takeLatest((action) => action.type === LOAD_PAGE && action.page === PAGE_NAME, pageSaga);
  yield takeLatest(UPDATE_ITEM_PRICE, itemSaga);
}

function* answerFlow() {
  yield takeLatest(ANSWER_CHANGE, answerChangeSaga);
  yield takeLatest(ANSWER_CHANGE_DEVICE_INSTANTIATE, answerDeviceChangeSaga);
  yield takeLatest(ANSWER_QUANTITY_CHANGE, answerChangeSaga);
  yield takeLatest(ADD_TO_CART, addToCartSaga);
  yield takeLatest(CONFIRM_ITEM, confirmCartItemSaga);
  yield takeLatest(ADD_TO_CART_Q_FLOW, addToCartQFlowSaga);
  yield takeLatest(ADD_PRODUCT_TO_CART, addProductToCartSaga);
  yield takeLatest(POPULATE_NEW_ITEM_WITH_CART_ANSWERS, populateNewItemWithCartAnswersSaga);
}

function* determineToRedirectToRecommendConfirm({ split, id }) {
  /* Lets see if this id even has recommendations. */
  if (!skuRecommendations.get(id)) return false;

  const { payload } = yield put(getTreatments({ splitNames: [split] }));

  try {
    const { treatment } = payload.treatments[split];
    return treatment === ON;
  } catch (err) {
    return false;
  }
}

export default [answerFlow, pageFlow];
