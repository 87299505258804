import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from 'src/components/HTKit/Grid';
import SubCategory from './SubCategory';
import { SKU_CATEGORY_IDS, SUB_CATEGORIES } from './constants';
import styles from './styles.scss';

const PrimaryMenu = ({
  groupedColumns,
  onPrimaryMenuItemClick,
  handleSubCategoryClick,
  skuLinkPrefix,
}) => {
  const { POPULAR_SERVICES, TV_MOUNTING, AROUND_THE_HOME } = SKU_CATEGORY_IDS;

  return groupedColumns.map((categories, index) => (
    <Grid.Column lg={3} key={index} classes={styles.categoryColumn}>
      {categories.map((category) => {
        const deviceCategory = SUB_CATEGORIES.find((cat) => cat.id === category.id);

        if ([TV_MOUNTING.id, AROUND_THE_HOME.id].includes(deviceCategory.id) ) {
          deviceCategory.subCategories = category.skus;
        }
        const categoryTitleStyles = cn('h6', styles.categoryItem, {
          [styles.popularCategoryTitle]: category.id === POPULAR_SERVICES.id,
        });
        return (
          <>
            <Link
              onClick={onPrimaryMenuItemClick}
              to={category.link}
              className={categoryTitleStyles}
            >
              {category.name}
            </Link>
            {deviceCategory.subCategories.map((subCategory) => {
              const onSubCategoryClick = (event) => handleSubCategoryClick(event, subCategory, category);
              return (
                <SubCategory
                  key={subCategory.id}
                  onClick={
                    (deviceCategory.showSecondaryNav || subCategory.showSecondaryNav) ? onSubCategoryClick : onPrimaryMenuItemClick
                  }
                  skuLinkPrefix={skuLinkPrefix}
                  sku={subCategory}
                />
              );
            })}
          </>
        );
      })}
    </Grid.Column>
  ));
};

PrimaryMenu.propTypes = {
  groupedColumns: PropTypes.array,
  onPrimaryMenuItemClick: PropTypes.func,
  handleSubCategoryClick: PropTypes.func,
  skuLinkPrefix: PropTypes.string,
};

export default PrimaryMenu;

/**
 * The reason we're checking this ->
 * (deviceCategory.showSecondaryNav || subCategory.showSecondaryNav)
 * is because TV Mounting & Popular category are main categories (deviceCategory) but the items under that are
 * all skus that navigates to skupage whereas subCategory is under deviceCategory and
 * clicking on those navigates to the next screen on top nav.
 * In the const file, all main categories(deviceCategory) had "showSecondaryNav", I moved that property
 * into subCategory and left it for popular & tv mounting since the new requirement is to add skus as subcategory.
 */
