import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { flow } from 'lodash/fp';
import cn from 'classnames';
import { toCommaSeparated } from  'src/utils/formatter/number';
import Star from './Star';
import styles from './styles.scss';

const SIZES = Object.freeze({
  sm: 14,
  md: 20,
  lg: 25,
});

const MAX = 5;

const enhance = flow(
  memo,
  // eslint-disable-next-line react/display-name, react/prop-types
  (Component) => ({ size, stars, onClick, count, ...rest }) => {
    const fullStars = Math.floor(stars);
    const partialStarValue = stars % 1;
    const emptyStars = MAX - (fullStars + (partialStarValue !== 0 ? 1 : 0));
    const props = {
      fullStars,
      partialStarValue,
      emptyStars,
      size: SIZES[size] || SIZES.md,
      onClick,
      tag: onClick ? 'a' : 'span',
      count: count && toCommaSeparated(count),
      ...rest,
    };
    return <Component {...props} />;
  },
);

const StarRating = ({
  size = 25,
  fullStars,
  partialStarValue,
  emptyStars,
  count,
  onClick,
  tag: Tag,
  className,
}) => {
  const handleClick = useCallback((event) => {
    event.preventDefault();
    if (onClick) onClick();
  }, []);
  return (
    <Tag className={cn(styles.rating, className)} onClick={handleClick}>
      {[...Array(fullStars)].map((_, idx) => (
        <Star value={1} size={size} key={`fs-${idx}`} className={styles.star} />
      ))}
      {partialStarValue !== 0 && (
        <Star value={partialStarValue} size={size} className={styles.star} />
      )}
      {[...Array(emptyStars)].map((_, idx) => (
        <Star value={0} size={size} key={`es-${idx}`} className={styles.star} />
      ))}
      {count && <span className={cn('caption n700', styles.count)}>{count}</span>}
    </Tag>
  );
};

StarRating.propTypes = {
  size: PropTypes.number,
  stars: PropTypes.number,
  count: PropTypes.number,
  fullStars: PropTypes.number,
  partialStarValue: PropTypes.number,
  emptyStars: PropTypes.number,
  onClick: PropTypes.func,
  tag: PropTypes.string,
  className: PropTypes.string,
};

export default enhance(StarRating);
