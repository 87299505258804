// Libraries
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

// Utils
import { formatDollarAmount } from 'src/utils/formatter/currency';

// Hooks and actions
import useAPI from 'src/hooks/useAPI';
import useNotice from 'src/hooks/useNotice';
import { useModalToggle } from 'src/components/HTKit/Modals/ModalV2';
import { getInstallationPrice } from 'src/containers/ProductPage/utils';
import { push, addSkuPath } from 'src/utils/paths';
import { productUpsellSegment } from 'src/containers/CartPage/actions';
import { logger } from 'src/utils/logger';
import { isImmutable } from 'src/utils/helpers';

// Components
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import Icon from 'src/components/HTKit/Icon';
import Grid from 'src/components/HTKit/Grid/Grid';
import Button, { THEMES } from 'src/components/HTKit/Forms/Button';
import ProductInstallModal from 'src/components/Product/ProductHeader/ProductInstallModal';

// Styles
import styles from './styles.scss';

const ProductInstallationUpsell = ({
  quantity,
  productId,
  index,
  className = 'marginTop-small',
  isUpsellCart = false,
  item,
  planIdForEstimate,
  isMemberCart,
}) => {
  const dispatch = useDispatch();
  const { error } = useNotice();
  const api = useAPI();
  const [installationSteps, setInstallationSteps] = useState([]);
  const [productVariantSku, setProductVariantSku] = useState(null);
  const [productSku, setProductSku] = useState(null);
  const [serviceOnlyPrice, setServiceOnlyPrice] = useState(null);
  const [installationPrice, setInstallationPrice] = useState(null);
  const [serviceOnlyPriceFormatted, setServiceOnlyPriceFormatted] = useState(null);
  const [installationPriceFormatted, setInstallationPriceFormatted] = useState(null);
  const { isVisible, toggle } = useModalToggle();

  /**
   * @type {{quantity: number, skuId: number}}
   */
  const itemJS = isImmutable(item) ? item.toJS() : item;

  const setNonMemberDataFromApiSuccess = ({ prices, steps, productVariant, sku }) => {
    setInstallationPrice(prices.installationPrice);
    setInstallationPriceFormatted(prices.installationPriceFormatted);
    setServiceOnlyPrice(prices.serviceOnlyPrice);
    setServiceOnlyPriceFormatted(prices.serviceOnlyPriceFormatted);
    setInstallationSteps(steps);
    setProductVariantSku(productVariant);
    setProductSku(sku);
  };
  // The base sku item needs to be fetched to get the installation steps
  const loadStandardDetails = useCallback(() => {
    api.toggleLoader(true);
    api.skus
      .find({ id: productId })
      .then(({ data, err }) => {
        if (err) throw err;
        const { sku } = data;
        if (sku.productVariants.length) {
          const {
            installation: { steps },
            productVariants,
          } = sku;
          const productVariant = productVariants[0];

          const prices = getInstallationPrice({
            product: sku,
            service: productVariant,
            quantity: itemJS.quantity,
          });
          setNonMemberDataFromApiSuccess({ prices, steps, productVariant, sku });
        }
        return null;
      })
      .catch((err) => {
        logger('CartPageProductInstallation')(err);
        error(err);
      })
      .finally(() => api.toggleLoader(false));
  });

  const getVariantMemberPrice = async (skuId, planId) => {
    try {
      const {
        data: { prices },
        err,
      } = await api.plans.subscriptions.estimate({ id: planId, skuId });
      if (err) throw new Error(err);
      return prices.cheapestPrice;
    } catch (err) {
      logger('CartPageProductInstallation')(err);
      return error(err);
    }
  };

  const loadDiscountedDetails = async () => {
    try {
      api.toggleLoader(true);
      const { data, err } = await api.skus.find({ id: productId });
      if (err) throw new Error(err);
      const { sku } = data;
      const {
        installation: { steps },
        productVariants = [],
      } = sku;
      if (productVariants.length) {
        /*
          We're using this alternate means of calculating the installation price because the base price of the variant sku
          does not take into account any product discounts.  We use estimated price of installation (taken from API call) and subtract
          the cost of the product line item in the cart to determine the discounted price of installation.
        */

        const productVariant = productVariants[0];
        const prices = getInstallationPrice({
          product: sku,
          service: productVariant,
          quantity: itemJS.quantity,
        });

        /**
         * @type{ null | number} - Member price of Product with Installation
         */
        const variantMemberPrice = await getVariantMemberPrice(
          productVariant.id,
          planIdForEstimate,
        );

        if (!variantMemberPrice) {
          // If there is no member price for the installation sku then use the non-member price details and exit function
          setNonMemberDataFromApiSuccess({ prices, steps, productVariant, sku });
          return;
        }

        const totalPriceWithInstallation = variantMemberPrice * itemJS.quantity;
        const memberInstallationPrice = totalPriceWithInstallation - itemJS.amount;

        setInstallationPrice(memberInstallationPrice);
        setInstallationPriceFormatted(
          formatDollarAmount({ amount: memberInstallationPrice, truncateEvenDollars: true }),
        );
        setServiceOnlyPrice(prices.serviceOnlyPrice);
        setServiceOnlyPriceFormatted(prices.serviceOnlyPriceFormatted);
        setInstallationSteps(steps);
        setProductVariantSku(productVariant);
        setProductSku(sku);
      }
    } catch (err) {
      logger('CartPageProductInstallation')(err);
      error(err);
    } finally {
      api.toggleLoader(false);
    }
  };

  useEffect(() => {
    if (isUpsellCart || isMemberCart) {
      loadDiscountedDetails();
    } else {
      loadStandardDetails();
    }
  }, [isUpsellCart, isMemberCart, itemJS.skuId]);

  /*
    We are editing the sku because the existing product sku in cart needs to be updated to the installation sku.
    Passing in the itemIndex and the quantity.
  */
  const goToEditSku = (e) => {
    e.preventDefault();
    dispatch(productUpsellSegment({ product: productSku }));
    dispatch(
      push(addSkuPath(productVariantSku.id), {
        mode: 'edit',
        itemIndex: index,
        quantity,
      }),
    );
  };

  const button = (
    <Button theme={THEMES.V2PRIMARY} onClick={goToEditSku}>
      Add Installation
    </Button>
  );

  const showSavingsPrice = installationPrice && serviceOnlyPrice;
  const showSavings = serviceOnlyPrice > installationPrice;

  const Prices = () => (
    <p className="p2">
      <span>Add Installation &amp; setup </span>
      {/*
        If for some reason we do not get installationPrice and servicePrice,
        the following will not show (It is a nice to have in AC)
      */}
      {!!showSavingsPrice && (
        <>
          <span> for only {installationPriceFormatted}</span>
          {showSavings && (
            <span className="paddingLeft-tiny line-through n300">{serviceOnlyPriceFormatted}</span>
          )}
        </>
      )}
    </p>
  );
  if (!productVariantSku) return null;
  return (
    <>
      <PanelV2 className={cn(className, styles.panel)}>
        <Grid.FullWidth classes="padding-small">
          <Grid.Row classes={styles.customRow}>
            <Grid.Column sm={3} md={5} lg={8}>
              <Grid.Row classes={styles.customRow}>
                <Icon name="tool" className={styles.icon} />
                <Grid.Column>
                  <Prices />
                  <Link className="p2" onClick={toggle}>
                    See Details
                  </Link>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column sm={1} mdOffset={0} md={3} lg={4} lgOffset={0} classes={styles.customCol}>
              <Link className={cn('p2', styles.desktop)} onClick={goToEditSku}>
                Add Installation
              </Link>
              <Link className={cn('p2', styles.mobile)} onClick={goToEditSku}>
                Add
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid.FullWidth>
        {isVisible && installationSteps && (
          <ProductInstallModal
            isVisible={isVisible}
            hide={toggle}
            header="Expert Install &amp; Setup"
            footerElement3={button}
            bullets={installationSteps}
          />
        )}
      </PanelV2>
    </>
  );
};

ProductInstallationUpsell.propTypes = {
  bullets: PropTypes.array,
  baseSku: PropTypes.object,
  quantity: PropTypes.number,
  productId: PropTypes.number,
  index: PropTypes.number,
  className: PropTypes.string,
  isUpsellCart: PropTypes.bool,
  item: PropTypes.object.isRequired,
  plansInfo: PropTypes.object,
  planIdForEstimate: PropTypes.number,
  isMemberCart: PropTypes.bool,
};

export default ProductInstallationUpsell;
