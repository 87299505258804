import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
// Selectors
import { purePageSelector } from 'src/selectors/page';
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
// Utils
import { getAnnualAndMonthlySplitPlanPricing } from 'src/components/Plans/Pricing/utils';
import { formatDollarAmount } from 'src/utils/formatter/currency';
// Components & Styles
import { ServiceUpsellPrice } from 'src/components/Service/ServiceInfo/Parts/ServiceUpsellLabel';
import Icon from 'src/components/HTKit/Icon';

import styles from './styles.scss';

const HeaderTabs = ({ cart, page, showStandardTab, setTab, plansInfo }) => {
  /* Methods */
  const showStandard = (e) => {
    e.preventDefault();
    setTab(true);
  };

  const showUpsell = (e) => {
    e.preventDefault();
    setTab(false);
  };

  const scrollToBenefits = (e) => {
    e.preventDefault();
    if (!showStandardTab) {
      e.stopPropagation();
      window.scrollTo({
        smooth: true,
        top: 670,
      });
    }
  };

  /* Data */
  const currentPageState = useSelector(purePageSelector('current'));
  const itemsTotalFormatted = cart.getIn(['breakdown', 'itemsTotalFormatted']);
  const estimatedCart = page.get('estimatedCart');
  const monthlyPrice = plansInfo.monthly.amount;
  const estimatedItemsTotalFormatted = estimatedCart.getIn(['breakdown', 'itemsTotalFormatted']);
  const cartItemsTotal = cart.getIn(['breakdown', 'itemsTotal']) || 0;
  const estimatedCartItemsTotal = estimatedCart.getIn(['breakdown', 'itemsTotal']) || 0;
  const estimatedTotalSavings = formatDollarAmount({
    amount: cartItemsTotal - estimatedCartItemsTotal,
    truncateEvenDollars: true,
  });
  const planInterval = '/mo';
  const planCostFormatted = `$${monthlyPrice}${planInterval}`;

  /* Splits */
  const { oldPrice, currentPrice, planPricingSplit } = getAnnualAndMonthlySplitPlanPricing(true);
  const splitPlanPricing = planPricingSplit ? (
    <>
      <ServiceUpsellPrice
        oldPrice={oldPrice}
        currentPrice={currentPrice}
        classes="n700 captionSmall"
      />
      {planInterval}
    </>
  ) : (
    planCostFormatted
  );
  // Do we want the upsell tab to be default selected?
  const {splitTreatment: optInUpsellTreatment, splitLoaded} = useSplitIOTreatment(splitioConstants.SPLITNAME_UPSELL_OPT_IN_CART_SUMMARY);
  const optInUpsellDefaultSelected = optInUpsellTreatment === splitioConstants.ON;

  /* Styles */
  const standardButtonStyles = classNames('plainButton', styles.standardButtonContainer, {
    [styles.selected]: showStandardTab,
  });

  const standardHeaderStyles = classNames({
    n700: !showStandardTab,
    caption: planPricingSplit,
    h6: !planPricingSplit,
  });

  const membershipStyles = classNames({
    caption: planPricingSplit,
    h6: !planPricingSplit,
  });

  const upsellButtonStyles = classNames('plainButton', styles.upsellButtonContainer, {
    [styles.selected]: !showStandardTab,
  });

  const infoButtonStyles = classNames('plainButton', styles.iconButton);

  const infoIconStyles = classNames(styles.infoIcon, {
    [styles.active]: !showStandardTab,
  });

  /* Components */
  const EstimatedTotalFormatted = () => (
    // Render 2 components because of SASS restrictions on @extend %h4-v2 within media query
    <>
      <h3 className="showMobileTablet h4">{estimatedItemsTotalFormatted}</h3>
      <h3 className="showDesktop h3">{estimatedItemsTotalFormatted}</h3>
    </>
  );

  const InfoButton = () => (
    <button onClick={scrollToBenefits} className={infoButtonStyles}>
      <Icon name="v2-info-icon-default" className={infoIconStyles} />
    </button>
  );

  const SavingsTabIndex = () => (
    // Render 2 components because of SASS restrictions on @extend %h4-v2 within media query
    <div className={styles.savingsTab}>
      {!currentPageState.loading && (
        <>
          <div className="showMobileTablet white h5">Save {estimatedTotalSavings}</div>
          <div className="showDesktop white h6">Save {estimatedTotalSavings}</div>
        </>
      )}
    </div>
  );

  useEffect(() => {
    /*
      This is for standard view. They want to keep upsell as default, so the measure is
      how well does standard view perform. I would think "upsell" would be the test.
    */
    if (optInUpsellDefaultSelected && splitLoaded) {
      setTab(true);
    } else {
      // This seems redundant, but it allows for dynamic changes to the split to be realized in real time.
      setTab(false);
    }
  }, [optInUpsellDefaultSelected, splitLoaded])
  return (
    <div className={styles.container}>
      <button onClick={showStandard} className={standardButtonStyles}>
        <div className={styles.desktopTabs}>
          <h3 className="paddingRight-small">{itemsTotalFormatted}</h3>
          <h6 className={standardHeaderStyles}>Standard Price</h6>
        </div>
        <div className="showMobileTablet">
          <p className={standardHeaderStyles}>Standard Price</p>
          <h4 className={styles.itemsTotal}>{itemsTotalFormatted}</h4>
        </div>
      </button>
      <button onClick={showUpsell} className={upsellButtonStyles}>
        <SavingsTabIndex />
        <div className="showMobileTablet">
          <p className={classNames(membershipStyles, styles.membershipYear)}>
            with {splitPlanPricing} Membership
          </p>
          <InfoButton />
          <EstimatedTotalFormatted />
        </div>
        <div className={styles.desktopTabs}>
          <EstimatedTotalFormatted />
          <div className="paddingLeft-small text-align-left">
            <h6>Members Price</h6>
            <p className="caption">
              {splitPlanPricing} Membership
              <InfoButton />
            </p>
          </div>
        </div>
      </button>
    </div>
  );
};

HeaderTabs.propTypes = {
  cart: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  showStandardTab: PropTypes.bool.isRequired,
  setTab: PropTypes.func.isRequired,
  plansInfo: PropTypes.object,
};

export default HeaderTabs;
