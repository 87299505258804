// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Utils
import { formatDollarAmount } from 'src/utils/formatter/currency';
import { getDiscountsTotal } from 'src/containers/CartPage/Parts/ServicesBreakdown/utils';
import { getTotalCreditAdjustments, addExplanationToAdjustment } from 'src/utils/cart';
// Components && Styles
import { Grid } from 'src/components/HTKit/Grid';
import { OrderSummary } from 'src/containers/CartPage/Parts';
import HeaderTabs from 'src/containers/CartPage/CartTypes/UpsellCart/Parts/HeaderTabs';
import StandardTab from 'src/containers/CartPage/CartTypes/UpsellCart/Parts/StandardTab';
import UpsellTab from 'src/containers/CartPage/CartTypes/UpsellCart/Parts/UpsellTab';
import desktopStyles from './styles.scss';

const Desktop = ({
  cart,
  page,
  removeItem,
  startBooking,
  goToAnotherService,
  goToEditSku,
  addPlanAndStartBooking,
  plansInfoJS,
  itemsTotalFormatted,
  totalFormatted,
  canAddAnotherService,
  coupon,
  couponText,
  couponValueFormatted,
  orderAdjustmentsNoCoupon,
  subscriptionCouponValue,
  subscriptionCoupon,
  onProductButtonClick,
}) => {
  const [showStandardTab, setShowStandardTab] = useState(false);

  const planCaptionText = 'First of 12 Membership Payments';
  const planCost = plansInfoJS.monthly.amount;

  const estimatedCart = page.get('estimatedCart');
  const estimatedTotalPrice = estimatedCart.getIn(['breakdown', 'totalFormatted']);
  const estimatedServiceTotalFormatted = estimatedCart.getIn(['breakdown', 'itemsTotalFormatted']);
  const estimatedGlobalAdjustments = estimatedCart.getIn(['breakdown', 'adjustments']).toJS() || [];
  const estimatedOrderAdjustmentsNoCoupon = addExplanationToAdjustment(
    estimatedGlobalAdjustments
      .filter((adj) => adj?.type !== 'coupon')
      .map(({ name, type, explanation, amountFormatted, originalAmountFormatted }) => ({
        name,
        type,
        explanation,
        amount: amountFormatted,
        originalAmount: originalAmountFormatted,
      })),
  ); // needs to match orderAdjustmentsNoCouponSelector
  const estimatedSubscriptionAdjustments =
    estimatedCart.getIn(['breakdown', 'subscriptionAdjustments']).toJS() || [];
  const estimatedCouponAdj =
    [...estimatedGlobalAdjustments, ...estimatedSubscriptionAdjustments].find(
      (adj) => adj?.type === 'coupon',
    ) || {};
  const estimatedCouponValueFormatted = estimatedCouponAdj.amountFormatted || '';
  const estimatedCoupon = estimatedCart.get('coupon');
  const estimatedCouponText = estimatedCoupon?.get('text');

  const orderSummaryProps = showStandardTab
    ? {
        totalFormatted, // cart subtotal
        amount: itemsTotalFormatted, // service total
        creditAdjustment: getTotalCreditAdjustments(cart?.toJS ? cart.toJS() : cart),
        couponDetails: {
          coupon,
          couponText,
          couponValue: subscriptionCoupon.size ? subscriptionCouponValue : couponValueFormatted,
          orderAdjustmentsNoCoupon,
        },
        isForEstimatedCart: false,
        shouldAddPlanToCart: false,
      }
    : {
        totalFormatted: estimatedTotalPrice,
        amount: estimatedServiceTotalFormatted,
        creditAdjustment: getTotalCreditAdjustments(estimatedCart?.toJS()),
        couponDetails: {
          coupon: estimatedCoupon,
          couponText: estimatedCouponText,
          couponValue: estimatedCouponValueFormatted,
          orderAdjustmentsNoCoupon: estimatedOrderAdjustmentsNoCoupon,
        },
        isForEstimatedCart: true,
        shouldAddPlanToCart: true,
      };

  const getBadgeText = () => {
    const targetCart = showStandardTab ? cart.toJS() : estimatedCart.toJS();
    const sumOfItemDiscounts = targetCart.breakdown.items.reduce(
      (acc, item) => getDiscountsTotal(item) + acc,
      0,
    );
    return sumOfItemDiscounts > 0
      ? `You saved ${formatDollarAmount({ amount: sumOfItemDiscounts, truncateEvenDollars: true })}`
      : null;
  };
  const badgeText = getBadgeText();

  return (
    <Grid.FullWidth classes={desktopStyles.container}>
      <Grid.Row>
        <Grid.Column sm={4} md={8} lg={8}>
          <HeaderTabs
            cart={cart}
            page={page}
            showStandardTab={showStandardTab}
            setTab={setShowStandardTab}
            plansInfo={plansInfoJS}
          />
          <StandardTab
            cart={cart}
            page={page}
            showStandardTab={showStandardTab}
            removeItem={removeItem}
            startBooking={startBooking}
            goToAnotherService={goToAnotherService}
            goToProductsShop={onProductButtonClick}
            goToEditSku={goToEditSku}
            canAddAnotherService={canAddAnotherService}
          />
          <UpsellTab
            page={page}
            showStandardTab={showStandardTab}
            removeItem={removeItem}
            addPlanAndStartBooking={addPlanAndStartBooking}
            goToAnotherService={goToAnotherService}
            goToProductsShop={onProductButtonClick}
            goToEditSku={goToEditSku}
            plansInfo={plansInfoJS}
            canAddAnotherService={canAddAnotherService}
          />
        </Grid.Column>

        <Grid.Column sm={4} md={8} lg={4}>
          <OrderSummary
            name="Due after service"
            caption="Service Total"
            planName={!showStandardTab}
            planText="Due today"
            planCaption={planCaptionText}
            planAmount={`${planCost}`}
            badgeText={badgeText}
            startBooking={startBooking}
            addPlanAndStartBooking={addPlanAndStartBooking}
            {...orderSummaryProps}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid.FullWidth>
  );
};

Desktop.propTypes = {
  cart: PropTypes.object,
  page: PropTypes.object.isRequired,
  removeItem: PropTypes.func.isRequired,
  startBooking: PropTypes.func.isRequired,
  goToAnotherService: PropTypes.func.isRequired,
  goToEditSku: PropTypes.func.isRequired,
  addPlanAndStartBooking: PropTypes.func.isRequired,
  plansInfoJS: PropTypes.object,
  breakdown: PropTypes.object,
  orderAdjustmentsNoCoupon: PropTypes.array,
  itemsTotalFormatted: PropTypes.string,
  totalFormatted: PropTypes.string,
  canAddAnotherService: PropTypes.bool,
  coupon: PropTypes.object,
  couponText: PropTypes.string,
  couponValueFormatted: PropTypes.string,
  subscriptionCouponValue: PropTypes.string,
  subscriptionCoupon: PropTypes.array,
  badgeSavings: PropTypes.shape({
    amount: PropTypes.number,
    amountFormatted: PropTypes.string,
  }),
  estimatedTotalSavings: PropTypes.number,
  onProductButtonClick: PropTypes.func,
};

Desktop.defaultProps = {
  orderAdjustmentsNoCoupon: [],
  breakdown: [],
  plansInfoJS: {},
};

export default Desktop;
