// Libraries
import { useCallback, useEffect, useState } from "react";
import throttle from 'lodash/throttle';
// Utils
import { getScreenSizes } from "src/utils/ui";

import useEventListener from 'src/hooks/useEventListener';
import BREAKPOINTS from 'src/components/HTKit/Grid/constants';

export const WIN_SM = 'windowSmall';
export const WIN_MD = 'windowMedium';
export const WIN_LG = 'windowLarge';
export const WIN_XL = 'windowExtraLarge';

// Widths were taken from ht_grid breakpoints
const determineWindowType = () => {
  const { width } = getScreenSizes();
  if (width < BREAKPOINTS.MD_GRID) {
    return WIN_SM;
  }
  if (width < BREAKPOINTS.LG_GRID) {
    return WIN_MD;
  }
  if (width < BREAKPOINTS.XL_GRID) {
    return WIN_LG;
  }
  return WIN_XL;
};

const useWindowType = () => {
  const [windowType, setWindowType] = useState(WIN_SM);

  useEffect(() => {
    setWindowType(determineWindowType());
  }, []);

  const handleResize = useCallback(
    throttle(() => {
      setWindowType(determineWindowType());
    }, 300),
    [],
  );

  useEventListener({ eventName: 'resize', handler: handleResize });
  
  return windowType;
};

export default useWindowType;
