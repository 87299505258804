import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { buildLocationsLink } from 'src/utils/locations';
import styles from './styles.scss';

const SubCategory = ({ sku, skuLinkPrefix, onClick }) => {
  /*
    This component handles linking to actual skus and also to subcategories. Don't use Link
    unless it's an actual sku.
  */
  const { link = '' } = sku;
  const builtLink = skuLinkPrefix ? buildLocationsLink({ prefix: skuLinkPrefix, link }) : link;
  const isSku = Boolean(sku.id);
  return isSku ? (
    <Link onClick={onClick} to={builtLink} className={styles.skuItem}>
      <p className="p2 n700">{sku.name}</p>
    </Link>
  ) : (
    <a onClick={onClick} className={styles.skuItem}>
      <p className="p2 n700">{sku.name}</p>
    </a>
  );
};

SubCategory.propTypes = {
  sku: PropTypes.object.isRequired,
  skuLinkPrefix: PropTypes.string,
  onClick: PropTypes.func,
};

export default React.memo(SubCategory);
