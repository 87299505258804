import React from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from 'src/components/HTKit/Icon';
import { prevented } from 'src/utils/event';
import { cartPath, push } from 'src/utils/paths';
import { getCartItemCount } from 'src/utils/cart';
import styles from 'src/components/Header/TopNavHeader/styles.scss';

const CartCount = ({ cart, themeStyles }) => {
  const itemCount = getCartItemCount(cart);
  const containerStyles = cn(styles.topNavItemCount, themeStyles);

  return itemCount > 0 ? <div className={containerStyles}>{itemCount}</div> : null;
};

const CartPreviewIcon = ({ cart, onClick, containerStyles, iconName, themeStyles, ...rest }) => {
  const dispatch = useDispatch();
  const goToCart = () => prevented(dispatch(push(cartPath)));

  const appliedContainerStyles = cn(styles.topNavCart, containerStyles, themeStyles);
  return (
    <div className={appliedContainerStyles} onClick={onClick || goToCart}>
      <Icon name={iconName} />
      <CartCount cart={cart} themeStyles={themeStyles} {...rest} />
    </div>
  );
};

export default CartPreviewIcon;

CartCount.propTypes = {
  cart: PropTypes.object.isRequired,
  themeStyles: PropTypes.string,
};

CartPreviewIcon.propTypes = {
  containerStyles: PropTypes.string,
  cart: PropTypes.object.isRequired,
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  themeStyles: PropTypes.string,
};

CartPreviewIcon.defaultProps = {
  containerStyles: '',
  iconName: 'cart-outlined',
};

/*
* This allows for any header to get the usage of the cart icon and badge number
*
* Usage:
*  <CartPreviewIcon
*      cart={cart}
*      containerStyles={containerStyles}
*      themeStyles={themeStyles}
*  />
*
* Note: Some assumptions can be made, so please see proptypes for required
*
* */
