import React, { useState, useEffect } from 'react';
import cn from 'classnames';
// Hooks && Utils
import { splitioConstants, useSplitIOTreatment } from 'src/components/SplitIO';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import useAPI from 'src/hooks/useAPI';
import { logger } from 'src/utils/logger';
import { getLowTierUpsellTitle } from './utils';
import { checkIsLowTierPlanInCart } from 'src/containers/BookingPage/SummaryPage/utils';
import { useUpsellManagement } from 'src/containers/BookingPage/SummaryPage/hooks/useUpsellManagement';
// Selectors
import {
  planIdSelector,
  cartItemsJSSelector,
  cartSelector,
  purePlanSelector,
  affirmPaymentSelectedSelector,
  itemsQuantitySelector,
} from 'src/selectors/cart';
import { purePageSelector } from 'src/selectors/page';
import { isEligibleToSeeLowTierUpsellSelector } from 'src/containers/BookingPage/SummaryPage/selectors';
import { SPLIT_KEYS } from './constants';
import { SUMMARY_PAGE_NAME_SHORT } from 'src/containers/BookingPage/SummaryPage/constants';
// Actions
import { updateLowTierConfig } from 'src/containers/BookingPage/SummaryPage/actions';
import { addPlanToCart, removePlanFromCart } from 'src/actions/cart';
import { updateTrackingByUpsell } from 'src/actions/tracking';
import { UPSELL_TRACKING } from 'src/constants/tracking';
// Components && Styles
import { PanelV2 } from 'src/components/HTKit/Elements/PanelV2';
import { CheckboxGroupV2, CheckboxV2 } from 'src/components/HTKit/Forms/CheckboxV2';
import Icon from 'src/components/HTKit/Icon';
import UpsellModal from './Parts/UpsellModal';
import UpsellBadge from './Parts/UpsellBadge';
import styles from './styles.scss';

const CHECKBOX_VALUES = { SUBSCRIBE: 'subscribe' };

const LowTierMembershipUpsell = () => {
  const affirmPayment = useSelector(affirmPaymentSelectedSelector);
  const isEligibleToSeeUpsell = useSelector(isEligibleToSeeLowTierUpsellSelector);

  const { SUBSCRIBE } = CHECKBOX_VALUES;

  const api = useAPI();

  const reduxDispatch = useDispatch();

  const pageReduxState = useSelector(purePageSelector(SUMMARY_PAGE_NAME_SHORT)) || {};

  const isPageTreatmentSet = Boolean(pageReduxState.lowTierConfig?.splitTreatment);

  // Get Redux Data
  const { splitLoaded, splitTreatment, splitConfig } = useSplitIOTreatment(
    splitioConstants.SPLITIONAME_LOW_TIER_MEMBERSHIP_V1,
  );
  const cart = useSelector(cartSelector);
  const cartPlanId = useSelector(planIdSelector);
  const cartItems = useSelector(cartItemsJSSelector);
  const cartPlanData = useSelector(purePlanSelector);
  const numItemsInCart = useSelector(itemsQuantitySelector);
  const splitTestPlanId = Number(splitConfig[SPLIT_KEYS.PLAN_ID]);
  const isLowTierPlanInCart = checkIsLowTierPlanInCart({ cart, splitConfig });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [planDetails, setPlanDetails] = useState({});

  // Device Details
  const getItemDeviceName = (item) => {
    const { physicalProduct, deviceName, brandName, skuCategoryName } = item || {};
    const fallback = 'device';
    if (!physicalProduct) return deviceName || fallback;
    if (brandName && skuCategoryName) return `${brandName} ${skuCategoryName}`;
    return fallback;
  };

  const itemDeviceName = getItemDeviceName(cartItems[0]);

  const formik = useFormik({
    initialValues: { subscribe: isLowTierPlanInCart ? [SUBSCRIBE] : [] },
  });
  const { addPlanCallback } = useUpsellManagement();

  // Add/Remove items
  const addLowTierPlanToCart = () => {
    reduxDispatch(
      addPlanToCart(splitTestPlanId, {
        successCB: addPlanCallback,
        errorCB: addPlanCallback,
      }),
    );
  };

  const removeLowTierPlanFromCart = () => {
    reduxDispatch(removePlanFromCart());
  };

  // Handle Checkbox
  const handleCheckbox = async (value) => {
    formik.setFieldValue(SUBSCRIBE, value);
    if (value.includes(SUBSCRIBE)) {
      return cartPlanId ? null : addLowTierPlanToCart();
    }
    return cartPlanId ? removeLowTierPlanFromCart() : null;
  };

  const setCheckboxTrue = () => handleCheckbox([SUBSCRIBE]);
  const setCheckboxFalse = () => handleCheckbox([]);

  // Upsell tracking
  const upsellTracking = (type) => {
    reduxDispatch(
      updateTrackingByUpsell({ plan: planDetails, partner: cart.partner, event: type }),
    );
  };

  // Handle Modal
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const processModalChoice = (addPlan) => () => {
    toggleModal();
    return addPlan ? setCheckboxTrue() : setCheckboxFalse();
  };

  // Grab plan data
  const getPlanDetails = async () => {
    if (cartPlanData && cartPlanData.id === splitTestPlanId) {
      return setPlanDetails(cartPlanData);
    }
    api.toggleLoader(true);
    try {
      const { err, data: { plan = {} } = {} } = await api.plans.subscriptions.show({
        id: splitTestPlanId,
      });

      api.toggleLoader(false);
      if (err) throw err;
      return setPlanDetails(plan);
    } catch (error) {
      return logger('LowTierMembershipUpsell')(error);
    }
  };

  const isPlanDetailsSet = Boolean(Object.keys(planDetails).length);
  const { name: planName, amount: planAmount } = planDetails;

  // Effects
  const isUpsellVisible =
    splitLoaded &&
    isPlanDetailsSet &&
    (isLowTierPlanInCart || !cartPlanId) &&
    isEligibleToSeeUpsell &&
    !affirmPayment;

  useEffect(() => {
    /*
      There may be multiple instantiations of this component on the same summary page due to the way the page is structured.
      This useEffect ensures that we only set the treatment once. If we do not this then the treatment will be reset any time
      the component unmounts/mounts, so we need to lift the state up.
    */
    const localTreatmentValue =
      !splitTreatment || splitTreatment === splitioConstants.CONTROL ? null : splitTreatment;

    const { subscription = [] } = formik.values;
    if (!isPageTreatmentSet && isUpsellVisible) {
      if (localTreatmentValue === splitioConstants.ON) setCheckboxTrue(); // Handles first component render
      if (isLowTierPlanInCart && !subscription.length) setCheckboxTrue(); // Handles page hard refresh
      reduxDispatch(updateLowTierConfig({ splitTreatment }));
    }
  }, [splitTreatment, isLowTierPlanInCart, isPageTreatmentSet, isUpsellVisible]);

  useEffect(() => {
    const noPlanDetails = splitTestPlanId && !isPlanDetailsSet;
    if (noPlanDetails) getPlanDetails();
  }, [splitTestPlanId, isPlanDetailsSet]);

  // Render

  const hasUpsellBeenViewed = pageReduxState.lowTierConfig.hasUpsellBeenViewed || false;

  useEffect(() => {
    if (isUpsellVisible && !hasUpsellBeenViewed) {
      /* Upsell tracking: viewed - useRef doesn't work here for firing once, as whole component re-renders each time */
      upsellTracking(UPSELL_TRACKING.viewed);
      reduxDispatch(updateLowTierConfig({ hasUpsellBeenViewed: true }));
    }
  }, [isUpsellVisible, hasUpsellBeenViewed]);

  const title = getLowTierUpsellTitle({
    numItemsInCart,
    deviceName: itemDeviceName,
  });

  if (!isUpsellVisible) return null;

  return (
    <>
      <PanelV2 className="marginBottom-small site-V2" largeBorderRadius mediumShadow>
        <section className={styles.offerContainer}>
          <div className="marginBottom-tiny1">
            <UpsellBadge>1 MONTH FREE</UpsellBadge>
          </div>

          <h5 className={cn('marginBottom-tiny1 white', styles.bodyCopy)}>{title}</h5>
          <p className={cn('caption white', styles.bodyCopy)}>
            1 month free then ${planAmount}/month, annual commitment
          </p>
          <Icon name="chat" className={styles.chatIcon} />
        </section>

        <section className={cn('paddingX-small', styles.checkboxContainer)}>
          <CheckboxGroupV2 value={formik.values[SUBSCRIBE]} onChange={handleCheckbox}>
            <CheckboxV2 value={SUBSCRIBE} label={`Get ${planName}`} />
          </CheckboxGroupV2>
          <Icon name="info-outline" className={cn('n300', styles.infoIcon)} onClick={toggleModal} />
        </section>
      </PanelV2>
      <UpsellModal
        isVisible={isModalOpen}
        onCancel={processModalChoice(false)}
        onConfirm={processModalChoice(true)}
        planAmount={planAmount}
        planName={planName}
        deviceName={itemDeviceName}
        itemsInCart={numItemsInCart}
        title={title}
      />
    </>
  );
};

export default LowTierMembershipUpsell;
