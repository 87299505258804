// Libraries
import React, { useCallback, useRef, useState, useEffect } from 'react';
import cn from 'classnames';
// Utils && Data
import useToggle from 'src/hooks/useToggle';
import useEventListener from 'src/hooks/useEventListener';
import useWindowType, { WIN_LG, WIN_XL } from 'src/hooks/useWindowType';
// Components
import ChatCallDropdown from '../Parts/ChatCallDropdown';
import TopNavItemsV2 from './TopNavItemsV2';
import { AccountMenuItems, LeftComponent, CenterComponent, RightComponent } from './Parts';
// Constants
import { TRANSPARENT_THEMES } from './constants';

// Styles
import styles from './styles.scss';

const TopNavHeader = (propAttr) => {
  const {
    actionableLogo,
    cart,
    clearAppNotices,
    homeUrl,
    hideMembership,
    isTransparent,
    logout,
    openLoginModal,
    showSiteLinks,
    sidebarIsOpen,
    snackbar,
    push,
    toggleSidebar,
    toggleTopnav,
    toggleSearchBar,
    searchBarIsOpen,
    topNavIsOpen,
    theme,
    user,
    currentPartnerLanding,
  } = propAttr;
  const windowType = useWindowType();

  const [atTopOfViewport, setAtTopOfViewport] = useState(true);
  const [showPhoneInfo, setShowPhoneInfo] = useState(false);
  const topNavHeaderContainer = useRef(null);
  const { toggleState: accountMenuIsOpen, toggle: toggleAccountMenuHook } = useToggle(false);

  const handleShowPhoneInfo = () => {
    setShowPhoneInfo(true);
  };
  const handleHidePhoneInfo = () => {
    setShowPhoneInfo(false);
  };

  const handleClick = () => {
    if (sidebarIsOpen) toggleSidebar();
    toggleSearchBar(!searchBarIsOpen);
    document.body.style.overflow = 'hidden';
  };
  const notices = snackbar.get('notices');
  const isNoticePresent = notices.toJS().length;

  const topNavIsTransparent =
    atTopOfViewport && isTransparent && !topNavIsOpen && !sidebarIsOpen && !accountMenuIsOpen;

  const containerStyles = cn('htHeaderContainer', styles.container, {
    [styles.transparent]: topNavIsTransparent,
    [styles.searchContainer]: searchBarIsOpen,
    [styles.showNavShadow]: topNavIsOpen,
  });

  const overlayStyles = cn({
    [styles.searchOverlay]: searchBarIsOpen,
  });

  const themeComponentStyles = cn({
    [styles['transparent-inverted']]: topNavIsTransparent && theme === TRANSPARENT_THEMES.INVERTED,
    [styles['transparent-classic']]: topNavIsTransparent && theme === TRANSPARENT_THEMES.CLASSIC,
  });

  const handleToggle = useCallback((e) => {
    if (e && e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    toggleTopnav();
  }, []);

  const toggleAccountMenu = (e) => {
    if (sidebarIsOpen) toggleSidebar();
    if (e && e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    toggleAccountMenuHook(!accountMenuIsOpen);
  };

  const handleToggleWithSnackbar = (e) => {
    if (e && e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    if (isNoticePresent) {
      clearAppNotices();
    }
    handleToggle();
  };

  const handleTransparentScroll = () => {
    if (!isTransparent) return null;
    if (window.scrollY === 0) return setAtTopOfViewport(true);
    return setAtTopOfViewport(false);
  };

  useEventListener({ eventName: 'scroll', handler: handleTransparentScroll });

  useEffect(() => {
    handleTransparentScroll();
  }, []);

  const handleIconClick = () => {
    toggleSearchBar(!searchBarIsOpen);
    setShowPhoneInfo(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      <div ref={topNavHeaderContainer} className={containerStyles}>
        {searchBarIsOpen && <div className={overlayStyles} onClick={handleIconClick} />}
        {!searchBarIsOpen && (
          <LeftComponent
            actionableLogo={actionableLogo}
            homeUrl={homeUrl}
            topNavIsTransparent={topNavIsTransparent}
            sidebarIsOpen={sidebarIsOpen}
            showSiteLinks={showSiteLinks}
            theme={theme}
            themeStyles={themeComponentStyles}
            toggleSidebar={toggleSidebar}
            currentPartnerLanding={currentPartnerLanding}
          />
        )}
        {!searchBarIsOpen && (
          <CenterComponent
            showSiteLinks={showSiteLinks}
            toggleTopnav={handleToggleWithSnackbar}
            topNavIsOpen={topNavIsOpen}
            hideMembership={hideMembership}
            themeStyles={themeComponentStyles}
          />
        )}
        <RightComponent
          cart={cart}
          onEnter={handleShowPhoneInfo}
          onLeave={handleHidePhoneInfo}
          push={push}
          showSiteLinks={showSiteLinks}
          toggleAccountMenu={toggleAccountMenu}
          themeStyles={themeComponentStyles}
          user={user}
          showSearchBar={searchBarIsOpen}
          searchClick={handleClick}
          onClose={handleIconClick}
          showPhoneInfo={showPhoneInfo}
        />
        {accountMenuIsOpen ? (
          <AccountMenuItems
            isSignedIn={Boolean(user)}
            openLoginModal={openLoginModal}
            signOut={logout}
            toggleAccountMenu={toggleAccountMenu}
          />
        ) : null}
        {topNavIsOpen && [WIN_LG, WIN_XL].includes(windowType) && (
          <TopNavItemsV2 toggleTopnav={handleToggle} />
        )}
      </div>
      <ChatCallDropdown />
    </>
  );
};

export default TopNavHeader;
