import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { homePath } from 'src/utils/paths';
import { resolveHomeUrl, usePartnerLogoUrl } from 'src/components/Header/utils';
import BookingHeaderLogo from './BookingHeaderLogo';
import styles from './styles.scss';

const BookingHeader = ({
  header,
  currentPartnerLanding,
}) => {
  const logoUrl = usePartnerLogoUrl();
  const actionable = header.get('actionableLogo');
  const homeUrl = header.get('homeUrl', homePath());
  const resolvedHomeUrl = resolveHomeUrl({ homeUrl, currentPartnerLanding });

  const wrapperStyles = cn(styles.logoWrapper, {
    [styles.withPartnerLogo]: logoUrl,
    [styles.noAction]: !actionable,
  });

  return (
    <nav className={styles.container}>
      {actionable ? (
        <Link to={resolvedHomeUrl} className={wrapperStyles}>
          <BookingHeaderLogo logo={logoUrl} />
        </Link>
      ) : (
        <div className={wrapperStyles}>
          <BookingHeaderLogo logo={logoUrl} />
        </div>
      )}
    </nav>
  );
};

BookingHeader.propTypes = {
  header: PropTypes.object.isRequired,
  currentPartnerLanding: PropTypes.object,
};

export default memo(BookingHeader);
