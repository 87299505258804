import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Grid } from 'src/components/HTKit/Grid';
import Breadcrumbs from 'src/components/Elements/Breadcrumbs';
import BackButton from 'src/components/Elements/BackButton';
import styles from './styles.scss';

const NavHeader = ({
  title = 'What can we help with?',
  currentItem,
  showSecondaryNav,
  onBackClick,
}) => {
  const navStyles = cn('paddingBottom-small2', styles.navHeader, {
    [styles.breadcrumbs]: showSecondaryNav,
    'paddingTop-small2': showSecondaryNav,
    'paddingTop-medium2': !showSecondaryNav,
  });
  const onBreadcrumbClick = (event) => {
    event.stopPropagation();
    const canApplyClick = event.target.innerText.toLowerCase().trim() === currentItem[0].label.toLowerCase().trim();
    if (canApplyClick) {
      onBackClick(event);
    }
  };

  return showSecondaryNav ? (
    <div className={navStyles}>
      <Grid.FullWidth classes="marginAuto flex">
        <BackButton className={cn('marginRight-small', styles.backButton)} onClick={onBackClick} />
        <div onClick={onBreadcrumbClick}>
          <Breadcrumbs items={currentItem} />
        </div>
      </Grid.FullWidth>
    </div>
  ) : (
    <div className={navStyles}>
      <Grid.FullWidth classes="marginAuto">
        <h3 className="darkBlue700">{title}</h3>
      </Grid.FullWidth>
    </div>
  );
};

NavHeader.propTypes = {
  title: PropTypes.string,
  currentItem: PropTypes.object,
  showSecondaryNav: PropTypes.bool,
  onBackClick: PropTypes.func,
};

export default NavHeader;
