import useAPI from 'src/hooks/useAPI';
import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { noop } from 'src/utils/event';
import { logger } from 'src/utils/logger';
import { RESEND_COOLDOWN_MS } from '../constants';
import { clearLoginPasscodeError, setLoginPasscodeError } from '../actions';
import { validateAuthCode } from '../../LoginForm/actions';

/**
 * Extracts the number of seconds from the error message if it contains a specific format.
 *
 * @param {string} errorMessage - The error message to extract seconds from.
 * @return {number|null} The number of seconds extracted from the error message, or null if not found.
 */
const parseCooldownTimeFromError = (errorMessage) => {
  const match = errorMessage?.match(/Please wait (\d+) seconds/);
  return match ? parseInt(match[1], 10) * 1000 : null;
};

/**
 * Manages the cooldown timer for passwordless login.
 *
 * @param {number} defaultCooldownTime - The default countdown time in milliseconds. Defaults to RESEND_COOLDOWN_MS.
 */
export const useCountdownTimer = (defaultCooldownTime = RESEND_COOLDOWN_MS) => {
  const [remainingTime, setRemainingTime] = useState(0);

  const loginPasscodeError = useSelector((state) =>
    state.getIn(['components', 'loginModal', 'loginPasscodeError']),
  );

  const cooldownTimeFromError = parseCooldownTimeFromError(loginPasscodeError);

  const currentCooldownTime = cooldownTimeFromError || defaultCooldownTime;

  const startTimer = useCallback(
    ({ startFromBeginning = false } = {}) => {
      const time = startFromBeginning ? defaultCooldownTime : currentCooldownTime;
      setRemainingTime(time);
    },
    [currentCooldownTime, defaultCooldownTime],
  );

  useEffect(() => {
    let timerId;

    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1000);
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [remainingTime]);

  const canResend = remainingTime === 0;

  return {
    /** The current remaining time in milliseconds. */
    remainingTime,
    /** A function to start the countdown timer. */
    startTimer,
    /** A boolean indicating whether resending is allowed (true when remainingTime is 0). */
    canResend,
  };
};

/** Handles sending auth codes for passwordless login. */
export const useSendAuthCode = () => {
  const api = useAPI();
  const dispatch = useDispatch();

  const sendAuthCode = useCallback(
    async ({ email = '', onSuccess = noop, onError = noop }) => {
      dispatch(clearLoginPasscodeError());
      api.toggleLoader(true);

      try {
        const response = await api.users.sendAuthCode({ email });

        if (response.err) {
          let errorMessage = 'An error occurred. Please try again.';
          // Check for a specific error status that indicates too many attempts,
          // e.g. "Please wait 30 seconds before attempting again."
          if (response.err.response.status === 422) {
            // Use the error message from the server if available
            errorMessage = response.data?.errors[0];
          }
          dispatch(setLoginPasscodeError({ error: errorMessage }));
        }
        onSuccess();
      } catch (error) {
        logger('sendAuthCode')(error);
        onError(error);
      } finally {
        api.toggleLoader(false);
      }
    },
    [api, dispatch],
  );

  return sendAuthCode;
};

/**
 * Manages passcode auth for passwordless login.
 *
 * This hook handles both verifying and resending passcodes. Note that verifying
 * the code is done in the VALIDATE_AUTH_CODE saga which handles its own success/error, so
 * no success/error handlers are needed for verification.
 *
 * @param {Object} options - The options for passcode authentication.
 * @param {Function} [options.onResendSuccess] - Callback function to be called on successful passcode resend.
 * @param {Function} [options.onResendError] - Callback function to be called on passcode resend error.
 */
export const usePasscodeAuth = ({ onResendSuccess = noop, onResendError = noop }) => {
  const dispatch = useDispatch();

  const loginModalState = useSelector((state) => state.getIn(['components', 'loginModal']));

  const email = loginModalState.get('email');
  const userInitials = loginModalState.get('userInitials');
  const phoneLastFour = loginModalState.get('phoneLastFour');
  const loginPasscodeError = loginModalState.get('loginPasscodeError');

  // The Passcode modal can be opened in normal login flow or during checkout.
  // If it's in the checkout flow, we need to pass the isPasswordlessCheckout flag
  // for downstream effects.
  const isPasswordlessCheckout = loginModalState.get('isPasswordlessCheckout');

  const sendAuthCode = useSendAuthCode();

  const verifyPasscode = async (code) => {
    dispatch(validateAuthCode({ code, email, isPasswordlessCheckout }));
  };

  const resendPasscode = async () => {
    sendAuthCode({ email, onSuccess: onResendSuccess, onError: onResendError });
  };

  return {
    /** Function to verify the passcode. */
    verifyPasscode,
    /** Function to resend the passcode. */
    resendPasscode,
    /** The email address associated with the authentication. */
    email,
    /** The initials of the user. */
    userInitials,
    /** The last four digits of the user's phone number. */
    phoneLastFour,
    /** Any error message related to passcode login */
    loginPasscodeError,
  };
};
