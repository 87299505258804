import React, { useState, useEffect } from 'react';
// import { fromJS } from 'immutable';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import useAPI from 'src/hooks/useAPI';
import useEventListener from 'src/hooks/useEventListener';
import { updateTrackingByUpsell } from 'src/actions/tracking';
import { pureCartSelector } from 'src/selectors/cart';
import { addPlanToCart } from 'src/actions/cart';
import { UPSELL_TRACKING } from 'src/constants/tracking';
import { ORDER_SUBSCRIPTION_INTENT_PLAN_ID } from 'src/containers/Account/OrderPage/constants';
import { logger } from 'src/utils/logger';
import { formatDollarAmount } from 'src/utils/formatter/currency';
import { getEstimatedCartDetails } from 'src/components/HTKit/Modals/MembershipUpsellModal/utils';
import { updateLowTierConfig } from 'src/containers/BookingPage/SummaryPage/actions';
import { pureUserSelector } from 'src/selectors/user';
// UI
import ReactModal from 'react-modal';
import Button, { THEMES as BUTTON_THEMES } from 'src/components/HTKit/Forms/Button';
import BREAKPOINTS from 'src/components/HTKit/Grid/constants';
import { Picture, TYPES } from 'src/components/Elements/Picture';
import desktopImagePng from 'src/images/man-and-woman-on-laptop-desktop.png';
import desktopImageWebp from 'src/images/man-and-woman-on-laptop-desktop.webp';
import mobileImagePng from 'src/images/man-and-woman-on-laptop-mobile.png';
import mobileImageWebp from 'src/images/man-and-woman-on-laptop-mobile.webp';
import styles from './styles.scss';

const imageSources = [
  { srcSet: desktopImagePng, media: `(min-width: ${BREAKPOINTS.LG_GRID}px)`, type: TYPES.PNG },
  {
    srcSet: desktopImageWebp,
    media: `(min-width: ${BREAKPOINTS.LG_GRID}px)`,
    type: TYPES.WEBP,
  },
  { srcSet: mobileImagePng, type: TYPES.PNG },
  { srcSet: mobileImageWebp, type: TYPES.WEBP },
];

const Disclaimer = () => {
  const textSectionOne = 'HelloTech home is an annual membership';
  const textSectionTwo = 'and renews in 1 year at the regular price.';
  const sharedStyles = cn('n300 caption', styles.disclaimer);
  return (
    <>
      <p className={cn(sharedStyles, styles.desktop)}>
        {textSectionOne}&nbsp;{textSectionTwo}
      </p>
      <p className={cn(sharedStyles, styles.mobile)}>
        {textSectionOne}
        <br />
        {textSectionTwo}
      </p>
    </>
  );
};

/** This matches the animation timing in styles.scss */
const ANIMATION_DELAY_MS = 200;
export const HTHUpsellModal = ({ isOpen }) => {
  // Modal Animation states
  const [animateIn, setAnimateIn] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [closeTimeoutMs, setCloseTimeoutMs] = useState(ANIMATION_DELAY_MS);

  // Plan/Cart data states
  const [planDetails, setPlanDetails] = useState(null);
  const [estimatedCart, setEstimatedCart] = useState({});

  // Tracking states
  const [hasTrackingEventFired, setHasTrackingEventFired] = useState(false);

  const cart = useSelector(pureCartSelector) || {};

  const dispatch = useDispatch();

  const closeModal = () => dispatch(updateLowTierConfig({ showHthModal: false }));

  const api = useAPI();

  const user = useSelector(pureUserSelector) || {};

  const cartItemsTotal = cart.breakdown?.itemsTotal;
  const estimatedCartItemsTotal = estimatedCart?.breakdown?.itemsTotal;
  const estimatedTotalSavingsAmount = cartItemsTotal - estimatedCartItemsTotal;
  const estimatedTotalSavings = formatDollarAmount({
    amount: estimatedTotalSavingsAmount,
    truncateEvenDollars: false,
  });
  const isCartDataLoadedAndHasSavings =
    Boolean(cartItemsTotal) &&
    Boolean(estimatedCartItemsTotal) &&
    Boolean(estimatedTotalSavingsAmount);

  const planDetailsAmount = planDetails?.amount || 0;

  /* Actions */
  const handleAddToCart = () => {
    const cancelPlanId = user?.account?.subscription?.planId || null;
    dispatch(addPlanToCart(ORDER_SUBSCRIPTION_INTENT_PLAN_ID, { cancelPlanId }));
    closeModal();
  };

  const getPlanDetails = async () => {
    api.toggleLoader(true);
    try {
      const { err, data: { plan = {} } = {} } = await api.plans.subscriptions.show({
        id: ORDER_SUBSCRIPTION_INTENT_PLAN_ID,
      });

      api.toggleLoader(false);
      if (err) throw err;
      return setPlanDetails(plan);
    } catch (error) {
      closeModal();
      return logger('HTHUpsellModal')(error);
    }
  };

  useEffect(() => {
    if (isOpen && !planDetails) {
      getPlanDetails();
      const estimatedCartDetails = getEstimatedCartDetails({
        api,
        planId: ORDER_SUBSCRIPTION_INTENT_PLAN_ID,
      });
      estimatedCartDetails
        .then((estimate) => {
          if (!estimate?.data?.cart) {
            closeModal();
          } else {
            setEstimatedCart(estimate?.data?.cart);
          }
        })
        .catch((err) => {
          logger('HTHUpsellModal - estimateCart')(err);
          closeModal();
        });
    }
  }, [isOpen, planDetails, cart]);

  /* Tracking */
  useEffect(() => {
    // Fire tracking event on modal open
    if (isOpen && planDetails && cart && !hasTrackingEventFired) {
      dispatch(
        updateTrackingByUpsell({
          plan: planDetails,
          partner: cart.partner,
          event: UPSELL_TRACKING.viewed,
        }),
      );
      setHasTrackingEventFired(true);
    }
  }, [isOpen, planDetails, cart, hasTrackingEventFired, dispatch]);

  /* Animation Handling */
  const updateCloseTimeout = () => {
    if (window.innerWidth < BREAKPOINTS.LG_GRID) {
      setCloseTimeoutMs(ANIMATION_DELAY_MS);
    } else {
      setCloseTimeoutMs(0);
    }
  };

  useEventListener({
    eventName: 'resize',
    handler: updateCloseTimeout,
  });

  useEffect(() => {
    updateCloseTimeout();
  }, []);

  const canOpenModal =
    isOpen &&
    planDetailsAmount > 0 && // Plan data is received
    isCartDataLoadedAndHasSavings;

  useEffect(() => {
    if (canOpenModal) {
      // Ensures animation variables are set right before the next repaint for smoother visuals
      requestAnimationFrame(() => {
        setAnimateIn(true);
        setAnimateOut(false);
      });
    } else {
      requestAnimationFrame(() => {
        setAnimateIn(false);
        setAnimateOut(true);
      });
    }
  }, [canOpenModal]);

  useEffect(() => {
    // estimatedCartItemsTotal initializes as undefined. So check for zero to make
    // sure we have an actual number
    if (estimatedTotalSavingsAmount === 0) {
      closeModal();
    }
  }, [estimatedTotalSavingsAmount]);

  const modalClasses = cn(styles.modalStyles, {
    [styles.slideUp]: animateIn,
    [styles.slideDown]: animateOut,
  });

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={canOpenModal}
      overlayClassName={styles.overlay}
      className={modalClasses}
      closeTimeoutMS={closeTimeoutMs}
    >
      <section className={cn(styles.content, styles.textAlignment)}>
        <div className={cn(styles.text, 'padding-medium')}>
          <p className={cn(styles.percentOff, 'marginBottom-tiny1 caption')}>
            Join today AT 50% off!
          </p>
          <p className="h2">
            Save <span className="teal">{estimatedTotalSavings}</span> on
            <br />
            this order with
            <br />
            HelloTech Home
          </p>

          <div
            className={cn(
              styles.savingsContainer,
              styles.textAlignment,
              'marginTop-small1 marginBottom-small1',
            )}
          >
            <p className="h4 n900">Only ${planDetailsAmount}/yr</p>
            <p className={cn(styles.basePrice, 'p1')}>$199/yr</p>
          </div>

          <Button theme={BUTTON_THEMES.V2PRIMARY} onClick={handleAddToCart}>
            Save with HelloTech Home
          </Button>
          <button
            onClick={closeModal}
            className="plainButton width-full marginTop-small1 marginBottom-small1"
          >
            <span className={styles.decline}>I don't want to save</span>
          </button>
          <Disclaimer />
        </div>
        <Picture
          sources={imageSources}
          className={styles.image}
          onLoad={(e) => e.currentTarget.classList.add(styles.loaded)}
        />
      </section>
    </ReactModal>
  );
};

HTHUpsellModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onDecline: PropTypes.func,
};
