import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import HT_INFO from 'src/constants/hellotech';
import { userSelector, userSubscriptionDetailsSelector } from 'src/selectors/user';
import { displayPhone } from 'src/utils/formatter/phone';
import callPhone from 'src/utils/callClickHandler';
import { HomePageSplitOverride } from './HomePageSplitOverride';
import { ICON_ORIENTATION, PLAN_IDS_FOR_PHONE_DISPLAY } from './constants';

export const PhoneDisplayLink = ({
  displayNumber,
  icon,
  iconOrientation,
  linkStyle,
  phoneLink, // Value is passed by connect() in src/components/PhoneDisplayLink/index.js
  phoneTitle, // Value is passed by connect() in src/components/PhoneDisplayLink/index.js
  callClick,
  splitCaller,
}) => {
  const num = displayPhone(phoneTitle);
  const iconIsLeft = iconOrientation === ICON_ORIENTATION.left;
  const iconIsRight = iconOrientation === ICON_ORIENTATION.right;

  const user = useSelector(userSelector);
  const userSubscriptionInfo = useSelector(userSubscriptionDetailsSelector) || {};

  if (!num) return null;

  let { link, title } = HomePageSplitOverride(phoneLink, num, splitCaller);

  if (!title && splitCaller) return null;

  if (user && PLAN_IDS_FOR_PHONE_DISPLAY.includes(userSubscriptionInfo.planId)) {
    link = HT_INFO.phone.customers.subscriptionHolder.default.link;
    title = HT_INFO.phone.customers.subscriptionHolder.default.title;
  }

  return (
    <a href={link} onClick={callPhone(callClick, title)} className={linkStyle}>
      {iconIsLeft && icon}
      {displayNumber ? title : ''}
      {iconIsRight && icon}
    </a>
  );
};

PhoneDisplayLink.propTypes = {
  callClick: PropTypes.func.isRequired,
  displayNumber: PropTypes.bool,
  linkStyle: PropTypes.string,
  icon: PropTypes.shape().isRequired,
  iconOrientation: PropTypes.string,
  phoneTitle: PropTypes.string.isRequired,
  phoneLink: PropTypes.string.isRequired,
  splitCaller: PropTypes.bool,
};

PhoneDisplayLink.defaultProps = {
  linkStyle: null,
  iconOrientation: ICON_ORIENTATION.right,
  splitCaller: false,
  phoneLink: HT_INFO.phone.customers.default.link,
};

export default PhoneDisplayLink;
