// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';
import Icon from 'src/components/HTKit/Icon';
// Styles
import styles from './styles.scss';

export const THEMES = {
  DEFAULT: 'default',
  SECONDARY: 'secondary',
  SCIENCEBLUE: 'scienceBlue',
  V2PRIMARY: 'v2Primary',
  V2SECONDARY: 'v2Secondary',
  V2SCIENCEBLUE: 'v2ScienceBlue',
  GRADIENTBLUE: 'gradientBlue',
  V2INVERTED: 'v2Inverted',
  V2TRANSPARENTWHITE: 'V2TransparentWhite',
  DANGER_PRIMARY: 'dangerPrimary',
  DANGER_SECONDARY: 'dangerSecondary',
  PALO_ALTO_PRIMARY: 'paloAltoPrimary',
  PALO_ALTO_SECONDARY: 'paloAltoSecondary',
};

const Button = (props) => {
  const { children, huge, icon, inlineBlock, isLink, isExternalLink, small, theme } = props;

  const themeProps = [
    'theme',
    'className',
    'huge',
    'small',
    'inlineBlock',
    'hugeV2',
    'smallV2',
    'mediumV2',
  ];
  const propsToRender = omit(props, themeProps);
  const className = classNames(
    styles.button,
    {
      [styles.buttonSecondary]: theme === THEMES.SECONDARY,
      [styles.buttonScienceBlue]: theme === THEMES.SCIENCEBLUE,
      [styles.buttonHuge]: huge,
      [styles.buttonSmall]: small,
      [styles.inlineBlock]: inlineBlock,
      [styles.buttonV2Primary]: theme === THEMES.V2PRIMARY,
      [styles.buttonV2Secondary]: theme === THEMES.V2SECONDARY,
      [styles.buttonV2ScienceBlue]: theme === THEMES.V2SCIENCEBLUE,
      [styles.buttonGradientBlue]: theme === THEMES.GRADIENTBLUE,
      [styles.buttonV2Inverted]: theme === THEMES.V2INVERTED,
      [styles.buttonDangerPrimary]: theme === THEMES.DANGER_PRIMARY,
      [styles.buttonDangerSecondary]: theme === THEMES.DANGER_SECONDARY,
      [styles.buttonV2TransparentWhite]: theme === THEMES.V2TRANSPARENTWHITE,
      [styles.buttonPaloAltoPrimary]: theme === THEMES.PALO_ALTO_PRIMARY,
      [styles.buttonPaloAltoSecondary]: theme === THEMES.PALO_ALTO_SECONDARY,
      [styles.buttonHugeV2]: props.hugeV2,
      [styles.buttonMediumV2]: props.mediumV2,
      [styles.buttonSmallV2]: props.smallV2,
      [styles.linkV2]: isLink,
    },
    props.className,
  );

  return isLink ? (
    <>
      {!isExternalLink ? (
        <Link to={props.goTo} className={className} {...propsToRender}>
          {children}
        </Link>
      ) : (
        <a href={props.goTo} className={className} {...propsToRender}>
          {children}
        </a>
      )}
    </>
  ) : (
    <button className={className} {...propsToRender}>
      <span className={styles.buttonText}>
        {icon && <Icon name={icon} className={styles.iconStyles} />}
        {children}
      </span>
    </button>
  );
};

Button.propTypes = {
  isLink: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  goTo: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  theme: PropTypes.string,
  huge: PropTypes.bool,
  small: PropTypes.bool,
  inlineBlock: PropTypes.bool,
  hugeV2: PropTypes.bool,
  smallV2: PropTypes.bool,
  mediumV2: PropTypes.bool,
  icon: PropTypes.string,
};

Button.defaultProps = {
  isLink: false,
  children: '',
  theme: THEMES.DEFAULT,
  huge: false,
  small: false,
  inlineBlock: false,
  icon: '',
};

export default Button;

/*
  Button:
    A wrapper around the <button> HTML element.

  Examples:
    import Button, { THEMES } from '../../components/HTKit/Forms/Button';
      ...
      ...
    <Button onClick={this.myFunc}>
      Display content goes here
    </Button>

    <Button onClick={this.myFunc} theme={THEMES.SECONDARY}>
      Display content goes here
    </Button>

  Properties:
    children: Any text passed between the opening & closing <Button> component.
    theme: The CSS theme to apply to the button. See const THEMES.

  * const propsToRender used for extra attributes passed to the element.
  * The THEMES constant is also exported. Pass the Obj/Key value as the theme prop.
*/
